import React, { useContext } from "react";
import { Box } from "@mui/material";
import { renderFrequentComponent } from "../constants";
import HeaderForWeb from "./HeaderForWeb";
import { WebPreviewContext } from "../../contexts/contexts";
import InvoiceInformation from "./InvoiceInformation";
import ProductList from "./ProductList";
import PaymentPageFooter from "../PaymentPageFooter";

const LeftSection = (props) => {
  const { previewColor, fontStyle } = props;

  // Get context value using consumer
  const WebPreviewProps = useContext(WebPreviewContext);

  return (
    <Box
      component="div"
      className="left-side-section"
      sx={{
        backgroundColor: previewColor,
        ...WebPreviewProps?.boxSx,
      }}
    >
      <Box className="left-container" width={WebPreviewProps?.commonWidth}>
        {renderFrequentComponent("accountHeader", {
          ...props,
          type: "web",
          visibleInPaymentPage: WebPreviewProps?.visibleInPaymentPage,
          expireTimeHandler: WebPreviewProps?.expireTimeHandler,
        })}
        <HeaderForWeb
          {...props}
          customClass={WebPreviewProps?.headerSectionCustomClass}
          visibleInPaymentPage={WebPreviewProps?.visibleInPaymentPage}
          displayAmountInCurrency={
            WebPreviewProps?.paymentPageSettings?.display_amount_in_currency
          }
          accountAsset={WebPreviewProps?.accountAsset}
        />
        {/* Invoice Information Section */}
        {WebPreviewProps?.isShowInvoiceInfo && (
          <InvoiceInformation {...props} webPreviewProps={WebPreviewProps} />
        )}
        {/* Product List */}
        {WebPreviewProps?.isShowProductsList && (
          <ProductList
            {...props}
            productsData={WebPreviewProps?.productsData}
            getDataFromReducer={WebPreviewProps?.getDataFromReducer}
            visibleInPaymentPage={WebPreviewProps?.visibleInPaymentPage}
          />
        )}
      </Box>
      <Box
        width={WebPreviewProps?.commonWidth}
        marginTop="auto"
        className="bottom-footer"
      >
        <PaymentPageFooter
          theme="dark"
          gridPadding="0 !important"
          fontStyle={fontStyle}
          paymentPageSettings={WebPreviewProps?.paymentPageSettings}
        />
      </Box>
    </Box>
  );
};

export default LeftSection;
