import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import CheckoutViewCalculationDrawer from "../CheckoutViewCalculationDrawer";
import PageTIDPreview from "../PageTIDPreview";
import { viewCalculation } from "../constants";
import { MobilePreviewContext } from "../../contexts/contexts";
import HeaderAmountSection from "../HeaderAmountSection";

const HeaderForMobile = (props) => {
  const {
    isPartialPaymentAvailable,
    fontStyle,
    confirmPayment,
    paymentData,
    visibleInPaymentPage,
    paymentDetailsObj,
    selectedAssetCurrency,
  } = props;
  const [showViewCalculationDrawer, setShowViewCalculationDrawer] =
    useState(false);

  const checkPartialPayment = isPartialPaymentAvailable && !confirmPayment;

  // Get context value using consumer
  const mobilePreviewProps = useContext(MobilePreviewContext);

  return (
    <>
      <Box className="new-mobile-header-section">
        {mobilePreviewProps?.TIDVisible ? (
          <PageTIDPreview
            {...props}
            visibleInPaymentPage={visibleInPaymentPage}
          />
        ) : (
          <HeaderAmountSection
            {...props}
            resolution="mobile"
            visibleInPaymentPage={visibleInPaymentPage}
          />
        )}

        {checkPartialPayment &&
          viewCalculation(
            fontStyle,
            setShowViewCalculationDrawer,
            { justifyContent: "center", mt: "10px" },
            { mt: "1px !important" }
          )}
        {checkPartialPayment && (
          <CheckoutViewCalculationDrawer
            fontFamily={fontStyle}
            openViewCalculationDrawer={showViewCalculationDrawer}
            closeViewCalculationDrawer={() =>
              setShowViewCalculationDrawer(false)
            }
            paymentData={paymentData}
            paymentDetailsObj={paymentDetailsObj}
            selectedAssetCurrency={selectedAssetCurrency}
          />
        )}
      </Box>
    </>
  );
};

export default HeaderForMobile;
