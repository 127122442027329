import React from "react";
import { Grid, Box } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import Text from "./Text/Text";
import { speed } from "./messages";
import Translation from "./Translation";
import { CustomLink } from "./Link/Link";

const PaymentPageFooter = ({
  fontStyle,
  theme = "light",
  gridPadding,
  paymentPageSettings = {},
  customFontColor = "",
}) => {
  const fontColor =
    theme === "dark"
      ? `${fontStyle.color || "#D8DCE1"} !important`
      : customFontColor || "#B5B9C5 !important";

  const termsAndPrivacyBox = (type, url) => {
    return (
      <CustomLink
        href={url || process.env.REACT_APP_PROMO_WEBSITE + type}
        target="_blank"
        size="small"
      >
        <Text
          size={12}
          sx={{ ...fontStyle, color: fontColor }}
          variant="inline"
          withIcon="start"
          font="regular"
          className="grey-text"
        >
          <Translation value={type} />
          {type === "terms" && <>&nbsp;&middot;&nbsp;</>}
        </Text>
      </CustomLink>
    );
  };

  const customStyle = {
    justifyContent: paymentPageSettings?.show_payment_page_speed_branding
      ? "center !important"
      : "space-between",
  };

  return (
    <Grid
      className="mobile-view-footer"
      margin="15px 0"
      padding={gridPadding}
      sx={customStyle}
    >
      {!paymentPageSettings?.show_payment_page_speed_branding && (
        <CustomLink
          size="small"
          href={process.env.REACT_APP_PROMO_WEBSITE}
          target="_blank"
        >
          <Text
            size={12}
            sx={{ ...fontStyle, color: fontColor }}
            variant="inline"
            withIcon="start"
            font="regular"
          >
            <LockIcon
              sx={{
                height: "19px",
                width: "20px",
                margin: "0px 5px 0 0px !important",
                color: fontColor,
              }}
            />
            <Translation value="securedBy" />
            <Text
              size={12}
              variant="inline"
              font="semibold"
              sx={{ color: fontColor, fontWeight: 600 }}
            >
              &nbsp;{speed}
            </Text>
          </Text>
        </CustomLink>
      )}
      <Box display="flex">
        {termsAndPrivacyBox("terms", paymentPageSettings?.terms_of_service_url)}
        {termsAndPrivacyBox("privacy", paymentPageSettings?.privacy_policy_url)}
      </Box>
    </Grid>
  );
};

export default PaymentPageFooter;
