import CommonCheckoutActions from "./CommonCheckoutActions";
import { errorGif } from "./images";

const AmountExceed = () => {
  return (
    <CommonCheckoutActions
      action="amountExceed"
      actionMainText="cannotOpenLink"
      actionGif={errorGif}
      actionSubText="amountIsExceed"
    />
  );
};

export default AmountExceed;
