import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
// import Translation from "./Translation";
import { getCurrentTimeInSecond } from "./constants";

const Timer = ({
  maxStartingTime = 600,
  fontStyle,
  getTimerTime,
  showTimerSection,
  setShowTimerSection,
  onClick,
  expiresAt,
}) => {
  const [timerCount, setTimerCount] = useState(
    getCurrentTimeInSecond(expiresAt)
  );

  useEffect(() => {
    if (expiresAt) {
      const timer = setInterval(() => {
        const currentSeconds = getCurrentTimeInSecond(expiresAt);
        setShowTimerSection(currentSeconds < maxStartingTime);
        setTimerCount(currentSeconds);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [expiresAt]);

  const renderTimerTime = () => {
    if (
      expiresAt &&
      `${Math.floor(timerCount / 60)}`.padStart(2, 0) === "00" &&
      `${timerCount % 60}`.padStart(2, 0) === "00"
    ) {
      getTimerTime(true);
    }
  };

  return (
    <>
      {showTimerSection && (
        <Box
          className="timer-content"
          component="div"
          onClick={onClick && onClick}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Text
              className={`default-text ${timerCount < 120 && "blink-me"}`}
              sx={{
                ...fontStyle,
                cursor: "pointer",
              }}
              size={14}
              variant="inline"
              font="regular"
            >
              {`${Math.floor(timerCount / 60)}`.padStart(2, 0)}:
              {`${timerCount % 60}`.padStart(2, 0)}
            </Text>
            {/* <Text
              className="grey-text"
              size={14}
              variant="inline"
              font="regular"
              sx={fontStyle}
            >
              <Translation value={"timerInstruction"} />
            </Text> */}
            {renderTimerTime()}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Timer;
