import React from "react";
import { Box } from "@mui/material";
import Text from "./Text/Text";
import { CustomTooltip } from "./Tooltip/Tooltip";

const PaymentDetailTitle = ({ pageTitle, textProps, fromDetails = false }) => {
  const textLength = fromDetails ? 77 : 91;

  const pageTitleText = () => {
    return (
      <Text
        variant="subtitle1"
        size={16}
        sx={{ ...textProps, wordBreak: "break-word" }}
        className="default-text multi-line-truncate page-title"
        font="regular"
      >
        {pageTitle}
      </Text>
    );
  };

  return pageTitle.length > textLength ? (
    <CustomTooltip
      enterTouchDelay={0}
      arrow={true}
      placement="top"
      text={
        <Text variant="subtitle" size={14}>
          {pageTitle}
        </Text>
      }
      className="custom-tooltip"
    >
      <Box sx={{ width: "fit-content", boxShadow: "none" }}>
        {pageTitleText()}
      </Box>
    </CustomTooltip>
  ) : (
    pageTitleText()
  );
};

export default PaymentDetailTitle;
