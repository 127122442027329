import { Switch, Route } from "react-router-dom";
import CheckoutPage from "../components/checkout";
import NotFoundLink from "@speed/common/src/components/NotFoundLink";
import DeactivatedLink from "@speed/common/src/components/DeactivatedLink";
import GoogleAnalytics from "@speed/common/src/components/GoogleAnalytics";
import UnderMaintenancePage from "@speed/common/src/components/UnderMaintenancePage";
import AlreadyPaidLink from "@speed/common/src/components/AlreadyPaidLink";
import AmountExceed from "@speed/common/src/components/AmountExceeded";

const Main = () => {
  return (
    <>
      <GoogleAnalytics />
      <Switch>
        <Route exact path="/pay/:id" component={CheckoutPage} />
        <Route
          exact
          path="/pay/success/paid-link"
          component={AlreadyPaidLink}
        />
        <Route
          exact
          path="/pay/error/deactivated-link"
          component={DeactivatedLink}
        />
        <Route
          exact
          path="/pay/error/not-found-link"
          component={NotFoundLink}
        />
        <Route
          exact
          path="/pay/error/under-maintenance"
          component={UnderMaintenancePage}
        />
        <Route
          exact
          path="/pay/error/amount-exceeded"
          component={AmountExceed}
        />
        <Route
          path="/"
          component={() => {
            window.location.href = process.env.REACT_APP_PROMO_WEBSITE;
            return null;
          }}
        />
      </Switch>
    </>
  );
};

export default Main;
