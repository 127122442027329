import React, { useEffect, useState } from "react";
import { Input } from "../Input/Input";
import CustomSelect from "../Select/Select";
import { MenuItem, Box } from "@mui/material";
import AlertMessage from "../AlertMessage/AlertMessage";
import {
  blockInvalidChar,
  checkSpecialCharacter,
  convertExponentialToDecimal,
} from "../constants";
import {
  dropdownLabel,
  dropdownPlaceHolder,
  maxLengthValidation,
  noOptions,
  numberLabel,
  textLabel,
  textPlaceHolder,
  validValue,
  whiteSpacePattern,
} from "../../messages";
import TruncatedTextTooltip from "../TruncatedTextTooltip";

const CustomFieldsPreview = (props) => {
  const {
    paymentInfo,
    isCheckedDetails,
    setCustomFieldDisable,
    contactDisable,
    setCustomFieldsData,
    setCustomFiledUpdatedFrom,
    customFieldUpdatedFrom,
    visibleInPaymentPage,
  } = props;
  const [customFieldData, setCustomFieldData] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (paymentInfo?.custom_fields?.length > 0) {
      const result = paymentInfo?.custom_fields.map((mainItem) => {
        const isAvailable =
          customFieldData.length > 0 &&
          customFieldData.find(
            (item) => item.type === mainItem.type && item.id === mainItem.id
          );
        const checkCFFrom =
          customFieldUpdatedFrom === "form-side" && isAvailable?.value === ""
            ? ""
            : mainItem?.value;

        return {
          ...mainItem,
          value: isAvailable?.value || checkCFFrom || "",
        };
      });
      setCustomFieldData(result);
    } else setCustomFieldData([]);
  }, [paymentInfo?.custom_fields, customFieldUpdatedFrom]);

  const handleChange = (value, objIndex, type) => {
    !visibleInPaymentPage && setCustomFiledUpdatedFrom("details-side");

    const result = customFieldData.map((item, index) => {
      const check1 =
        (type === "text" || type === "number") && value.length > 250;
      const check2 = type === "text" && value && !whiteSpacePattern.test(value);
      const errorCheck = check1 || check2;
      const itemValue =
        type === "number" ? convertExponentialToDecimal(value) : value;

      let errorMsg = "";
      if (check1) errorMsg = maxLengthValidation("Value", 250);
      else if (check2) errorMsg = validValue;

      if (item.type === type && index === objIndex) {
        return {
          ...item,
          value: itemValue,
          error: errorCheck,
          errorMsg,
        };
      } else {
        return { ...item };
      }
    });
    setCustomFieldData(result);
  };

  useEffect(() => {
    if (customFieldData.length > 0) {
      const errorArr = [];
      customFieldData.forEach((item) => {
        errorArr.push((item.is_optional ? false : !item.value) || item.error);
      });
      setIsFormValid(!errorArr.includes(true));
      setCustomFieldDisable(errorArr.includes(true));
    }
  }, [contactDisable, customFieldData]);

  useEffect(() => {
    const formattedCFArr =
      customFieldData.length > 0 &&
      customFieldData.map((item) => {
        const { id, label, type, is_optional, options, value } = item;
        let params = { label, type, is_optional, id, value: value?.trim() };
        if (type === "dropdown") {
          const optionsArr = options.map((option) => option.option);
          params["options"] = optionsArr;
        }
        return params;
      });
    setCustomFieldsData({
      payloadData: formattedCFArr,
      isValidToCallAPI: isFormValid,
    });
  }, [customFieldData, isFormValid]);

  return (
    customFieldData?.length > 0 &&
    customFieldData.map((item, index) => {
      const { label, type } = item;
      let staticLabel;
      if (type === "text") {
        staticLabel = textLabel;
      } else if (type === "number") {
        staticLabel = numberLabel;
      } else {
        staticLabel = dropdownLabel;
      }
      const inputLabel = label || staticLabel;
      const optionsArr = item.options?.filter((option) => option.option !== "");
      return (
        <Box
          margin={isCheckedDetails ? "30px 0 0 0" : "0 0 30px 0"}
          key={`custom_field${index}`}
          className="custom-field-preview"
        >
          {type === "text" || type === "number" ? (
            <>
              <Input
                customClass="custom-field-label"
                label={inputLabel}
                value={item.value || ""}
                name={type === "text" ? "text_input" : "number_input"}
                placeholder={textPlaceHolder}
                type={type}
                showLabel={true}
                fullWidth={true}
                error={item.error || false}
                onChange={(e) => handleChange(e.target.value, index, type)}
                onKeyDown={(e) => {
                  if (type === "number") {
                    checkSpecialCharacter(e);
                    blockInvalidChar(e);
                  }
                }}
              />
              {item.error && (
                <AlertMessage
                  key={`type_${index}`}
                  message={item.errorMsg}
                  className="margin-top15"
                  severity="error"
                  sx={{
                    margin: "auto",
                  }}
                />
              )}
            </>
          ) : (
            <CustomSelect
              native={false}
              showLabel={true}
              placeholder={dropdownPlaceHolder}
              fullWidth={true}
              label={inputLabel}
              value={item.value}
              onChange={(e) => handleChange(e.target.value, index, type)}
              MenuProps={{
                id: "custom-field-options",
              }}
              sx={{
                ".MuiSelect-outlined": {
                  paddingRight: "32px !important",
                },
              }}
              renderValue={() => item.value || <Box>{dropdownPlaceHolder}</Box>}
            >
              {optionsArr?.length > 0 ? (
                optionsArr.map((option) => (
                  <MenuItem
                    key={option.option}
                    value={option.option}
                    className={
                      visibleInPaymentPage ? "custom-field-option-menu" : ""
                    }
                  >
                    <TruncatedTextTooltip
                      textValue={option.option}
                      cellWidth="315px"
                      className="custom-tooltip"
                    />
                  </MenuItem>
                ))
              ) : (
                <MenuItem>{noOptions}</MenuItem>
              )}
            </CustomSelect>
          )}
        </Box>
      );
    })
  );
};

export default CustomFieldsPreview;
