import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Text from "./Text/Text";
import Translation from "./Translation";
import ImageIcon from "@mui/icons-material/Image";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ProductBillingInfo from "./ProductBillingInfo";
import { getAmountByCurrency, getCurrencyObj } from "./constants";
import TruncatedTextTooltip from "./TruncatedTextTooltip";
import { CustomLink } from "./Link/Link";
import PaymentDetailDrawer from "./PaymentDetailDrawer";
import CustomDivider from "./Divider/Divider";

const InvoicePageProductsModal = ({
  from,
  fontFamily,
  color,
  products,
  isShowViewMoreDetails,
  paymentInfo,
  visibleInPaymentPage,
  isShowItemsHeader = false,
  isShowTotal = false,
  isShowDivider = false,
  shouldBackgroundBlur = false,
  showProductImage = true,
  getDataFromReducer = false,
  ...props
}) => {
  const isGreyColor = isShowTotal ? "grey-text" : "";
  const showLessItems = ["web", "invoice"];

  const [paymentDetailsDrawer, setPaymentDetailsDrawer] = useState(false);

  const commonCss = {
    wordBreak: "break-all",
  };

  useEffect(() => {
    const classToSelect =
      from === "invoice" ? ".invoice-page-box" : ".invoice-payment-page";

    let element = document.querySelector(
      `${classToSelect} .latest-mobile-design`
    )?.style?.filter;
    if (shouldBackgroundBlur) {
      element = "blur(6px)";
    }

    return () => {
      if (shouldBackgroundBlur) {
        element = "none";
      }
    };
  }, []);

  return (
    <Box className="mobile-modal">
      <Box className="mobile-modal-list">
        {isShowItemsHeader && (
          <>
            <Text
              fontFamily={fontFamily}
              color={color}
              size={16}
              font="semibold"
              variant="h5"
              fontWeight="500"
              sx={{
                marginBottom: "24px",
                marginTop: !isShowDivider ? "20px" : "",
              }}
            >
              <Translation value="items" />
            </Text>
            {isShowDivider && <CustomDivider />}
          </>
        )}
        {(showLessItems.includes(from)
          ? [...products]?.splice(0, 2)
          : products
        )?.map((paymentItem) => {
          return (
            <Box className="product-list-item" key={paymentItem?.id}>
              {showProductImage && (
                <Box className="content-center product-image">
                  {paymentItem?.product_images?.length ? (
                    <img
                      src={paymentItem?.product_images[0]}
                      height="50px"
                      width="50px"
                    />
                  ) : (
                    <ImageIcon className="image-icon" />
                  )}
                </Box>
              )}
              <Box width="100%">
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" flexDirection="column">
                    <TruncatedTextTooltip
                      textValue={paymentItem.name}
                      cellWidth="200px"
                      textProps={{
                        size: 16,
                        variant: "subtitle1",
                        font: "semibold",
                        fontFamily: fontFamily,
                        color: color,
                        fontWeight: 600,
                      }}
                    />
                    <Box>
                      <Text
                        size={14}
                        variant="inline"
                        className={isGreyColor}
                        font="regular"
                        fontFamily={fontFamily}
                        color={color}
                      >
                        <Translation value="qty" /> {paymentItem.quantity}
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="end">
                    <Text
                      size={16}
                      sx={commonCss}
                      variant="subtitle1"
                      font="semibold"
                      fontFamily={fontFamily}
                      color={color}
                      fontWeight={600}
                    >
                      {getCurrencyObj(paymentItem?.currency).symbol}
                      {getAmountByCurrency(
                        paymentItem?.row_total_amount,
                        paymentItem?.currency
                      )}
                    </Text>
                    <Text
                      font="regular"
                      className={isGreyColor}
                      size={14}
                      sx={commonCss}
                      variant="subtitle1"
                      fontFamily={fontFamily}
                      color={color}
                    >
                      {getCurrencyObj(paymentItem?.currency).symbol}
                      {getAmountByCurrency(
                        paymentItem?.unit_amount,
                        paymentItem?.currency
                      )}{" "}
                      each
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
        {isShowTotal && (
          <ProductBillingInfo
            from={from}
            paymentInfo={paymentInfo}
            user={props?.user}
            fontFamily={fontFamily}
            getDataFromReducer={getDataFromReducer}
          />
        )}
        {isShowViewMoreDetails && (
          <Box className="view-more-detail">
            <CustomLink
              size="small"
              onClick={() => setPaymentDetailsDrawer(true)}
              alignIcon="end"
              color={color}
              sx={{ fontSize: "10px" }}
              withIcon={
                <KeyboardArrowRightIcon
                  style={{ width: 20, height: 20, color: color }}
                />
              }
            >
              <Text size="16" fontFamily={fontFamily}>
                <Translation value="viewMoreDetails" />
              </Text>
            </CustomLink>
          </Box>
        )}

        {visibleInPaymentPage && (
          <PaymentDetailDrawer
            fontFamily={fontFamily}
            openPaymentDetailDrawer={paymentDetailsDrawer}
            handleCloseDrawer={() => setPaymentDetailsDrawer(false)}
            paymentInfo={paymentInfo}
            products={products}
          />
        )}
      </Box>
    </Box>
  );
};

export default InvoicePageProductsModal;
