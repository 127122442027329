import React from "react";
import { Box } from "@mui/material";
import Text from "../Text/Text";
import CustomDivider from "../Divider/Divider";
import EditIcon from "@mui/icons-material/Edit";
import {
  billingAddress,
  contactInfo,
  details,
  edit,
  shippingAddress,
} from "../messages";
import { noDataAdded } from "../../messages";
import { isEmpty } from "lodash";

const PreviewDetailsSection = ({
  setShowEditedContactInfo,
  customerCollections,
  customFieldsDetails,
}) => {
  const commonStyleTextBox = { marginTop: "14px", wordBreak: "break-word" };
  const commonStyleText = { marginTop: "8px", wordBreak: "break-word" };

  const billingAndShippingAddressComponent = (address, customerCollections) => {
    const { name, line1, city, state, postal_code, country } =
      customerCollections;
    return (
      <>
        <Text
          style={commonStyleTextBox}
          className="default-text contact-address"
          font="medium"
          size={16}
          variant="subtitle1"
        >
          {address}
        </Text>
        {
          <Text
            style={commonStyleTextBox}
            className="grey-text  contact-name"
            font="medium"
            size={14}
            variant="subtitle1"
          >
            {name}
          </Text>
        }
        <Text
          style={commonStyleText}
          className="grey-text contact-line1"
          font="medium"
          size={14}
          variant="subtitle1"
        >
          {line1 + ","}
        </Text>
        <Text
          style={commonStyleText}
          className="grey-text contact-state-code"
          font="medium"
          size={14}
          variant="subtitle1"
        >
          {city} {state} {postal_code}
          {","}
        </Text>
        <Text
          style={commonStyleText}
          className="grey-text contact-country"
          font="medium"
          size={14}
          variant="subtitle1"
        >
          {country}
        </Text>
      </>
    );
  };

  const renderCFData = () => {
    return customFieldsDetails.every(
      (item) => item.is_optional && !item.value
    ) && isEmpty(customerCollections) ? (
      <Text
        size={14}
        variant="subtitle1"
        font="medium"
        className="grey-text margin-top15"
      >
        {noDataAdded}
      </Text>
    ) : (
      customFieldsDetails.map((item, index) => {
        return (
          item.value && (
            <Box className="margin-top15" key={index}>
              <Text
                size={16}
                variant="subtitle1"
                font="semibold"
                className="default-text"
                sx={{ wordBreak: "break-word" }}
              >
                {item.label}
              </Text>
              <Text
                size={14}
                variant="subtitle1"
                font="medium"
                className="grey-text"
                sx={{ wordBreak: "break-word" }}
              >
                {item.value}
              </Text>
            </Box>
          )
        );
      })
    );
  };
  const customFieldsComponent = () => {
    return customFieldsDetails?.length > 0 && renderCFData();
  };

  return (
    <Box mt="40px" mb="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text className="default-text" font="regular" size={16}>
          {details}
        </Text>
        <Box
          style={{ cursor: "pointer" }}
          display="flex"
          alignItems="center"
          onClick={() => setShowEditedContactInfo(true)}
        >
          <EditIcon className="edit-icon-contact" />
          <Text
            style={{ color: "#2A67FF", marginLeft: "8px" }}
            className="default-text"
            font="regular"
            size={16}
          >
            {edit}
          </Text>
        </Box>
      </Box>
      <CustomDivider style={{ marginTop: "10px" }} />

      {(customerCollections?.email || customerCollections?.phone) && (
        <>
          <Text
            style={commonStyleTextBox}
            className="default-text"
            font="regular"
            size={16}
          >
            {contactInfo}
          </Text>
          <Text
            style={commonStyleTextBox}
            className="grey-text"
            font="medium"
            size={14}
            variant="subtitle1"
          >
            {customerCollections?.email}
          </Text>
          <Text
            style={commonStyleText}
            className="grey-text"
            font="medium"
            size={14}
            variant="subtitle1"
          >
            {customerCollections?.phone}
          </Text>
        </>
      )}
      {customerCollections?.billing_address &&
        billingAndShippingAddressComponent(
          billingAddress,
          customerCollections?.billing_address
        )}
      {customerCollections?.shipping_address &&
        billingAndShippingAddressComponent(
          shippingAddress,
          customerCollections?.shipping_address
        )}
      {customFieldsComponent()}
    </Box>
  );
};

export default PreviewDetailsSection;
