import { QRCodeSVG } from "qrcode.react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { QRLogo } from "../images";

export const QRCodeComponent = ({
  value,
  size,
  icon,
  src,
  iconSize,
  level,
  includeMargin,
  borderRadius,
  ...props
}) => {
  const imageSettings = icon
    ? {
        src: src || QRLogo,
        height: iconSize,
        width: iconSize,
        excavate: true,
      }
    : {};

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: props.from === "oneQR" ? "29px 0px 32px 0px" : 0,
      }}
      className="qr-code"
    >
      <QRCodeSVG
        level={level}
        value={value}
        imageSettings={imageSettings}
        includeMargin={includeMargin}
        {...props}
        style={{
          ...props.style,
          height: size,
          width: size,
          borderRadius: borderRadius,
        }}
      />
    </Box>
  );
};

export const propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  icon: PropTypes.bool,
  src: PropTypes.string,
  iconSize: PropTypes.number,
  level: PropTypes.oneOf(["L", "M", "H", "Q"]),
  borderRadius: PropTypes.string,
  includeMargin: PropTypes.bool,
};

const defaultProps = {
  level: "M",
  includeMargin: false,
  borderRadius: "",
};

QRCodeComponent.propTypes = propTypes;
QRCodeComponent.defaultProps = defaultProps;
