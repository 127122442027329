import React, { useState } from "react";
import Translation from "./Translation";
import { Box } from "@mui/material";
import Text from "./Text/Text";

const PaymentDetailDescription = ({
  pageDescription,
  textProps = {},
  isFromDetail = false,
  boxStyle = {},
}) => {
  const [showMore, setShowMore] = useState(false);

  const fontStyle = isFromDetail
    ? {
        font: "regular",
        size: 16,
        className: "default-text",
      }
    : {
        font: "semi-bold",
        size: 14,
        className: "grey-text",
      };

  const showDots = pageDescription?.length > 134 ? "..." : "";
  return (
    <Box className="page-description-preview-box" component="div" sx={boxStyle}>
      <Text
        variant="subtitle1"
        size={fontStyle.size}
        font={fontStyle.font}
        sx={{
          ...textProps,
          wordBreak: "break-word",
        }}
        className={fontStyle.className}
      >
        {showMore
          ? `${pageDescription} `
          : `${pageDescription?.substring(0, 135)}${showDots}`}
        {pageDescription?.length > 134 && (
          <Text
            size={14}
            variant="inline"
            onClick={() => setShowMore(!showMore)}
            className="default-text"
            font="regular"
            sx={{
              fontFamily: textProps?.fontFamily || "Inter-regular",
              textDecoration: "underline",
              cursor: "pointer",
              color: textProps?.color,
            }}
          >
            {showMore ? (
              <Translation value="viewLess" />
            ) : (
              <Translation value="viewMore" />
            )}
          </Text>
        )}
      </Text>
    </Box>
  );
};

export default PaymentDetailDescription;
