import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useState } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "./Button/Button";
import {
  cashbackRedeemImage,
  tickGif,
  cashbackSuccessRedeemGif,
} from "./images";
import Text from "./Text/Text";
import * as yup from "yup";
import { useFormik } from "formik";
import { QRCodeComponent } from "./QRCode/QRCode";
import {
  cancelCashbackLabel,
  congratulationText,
  emailQRLabel,
  gotCashbackText,
  sendEmailCashbackLabel,
  sendEmailCashbackPlaceholder,
  successRedeemText,
} from "../messages";
import { invalidEmail } from "./messages";
import { Input } from "./Input/Input";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AlertMessage from "./AlertMessage/AlertMessage";
import { callAPIInterfaceCheckout } from "@speed/checkout/src/components/constants";

const CashbackScanModal = ({
  cashbackDetails,
  handleCloseCashbackModal,
  setRedirectToSuccessURL,
  checkoutSessionId,
}) => {
  const [isEmailQR, setIsEmailQR] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const showFooter = false;
  const successFullyReedemed = cashbackDetails?.status !== "active";
  const cashbackAmount = cashbackDetails?.target_amount_paid
    ? `${cashbackDetails.target_amount_paid} SATS`
    : "";
  const cashbackQRWithdrawAddress = cashbackDetails?.withdraw_request
    ? cashbackDetails.withdraw_request
    : "";

  const validationSchema = yup.object({
    email: yup.string().email(invalidEmail),
  });

  const handleEmail = () => {
    setLoading(true);
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleEmail,
  });

  const {
    values,
    touched,
    errors,
    setTouched,
    setFieldValue,
    isValid,
    isSubmitting,
    dirty,
    setSubmitting,
    handleSubmit,
  } = formik;

  const handleCloseDialogModal = () => {
    handleCloseCashbackModal();
    setRedirectToSuccessURL(true);
  };
  const handleCancelCashback = () => {
    callAPIInterfaceCheckout(
      "POST",
      `/checkout-sessions/${checkoutSessionId}/cashback/cancel`,
      {}
    ).finally(() => {
      handleCloseDialogModal();
    });
  };

  const emailQRSection = (
    <>
      <Box className="email-qr">
        <Button onClick={() => setIsEmailQR(true)} label={emailQRLabel} />
      </Box>
      <Box className="cancel-cashback" onClick={handleCloseCashbackModal}>
        <Text className="default-text" variant="h4" size={16} font="regular">
          {cancelCashbackLabel}
        </Text>
      </Box>
    </>
  );

  const sendEmailSection = (
    <Box className="email-section" component="form" onSubmit={handleSubmit}>
      <Box className="send-cashback-email">
        <Box className="send-email-input">
          <Input
            showLabel={false}
            placeholder={sendEmailCashbackPlaceholder}
            name="email"
            value={values.email}
            type="email"
            onBlur={() => setTouched({ ...touched, email: true })}
            onChange={(e) => {
              setSubmitting(false);
              setTouched({ ...touched, email: false });
              setFieldValue("email", e.target.value);
            }}
            error={touched.email && Boolean(errors.email)}
          />
        </Box>
        <Box className="send-email-button">
          <Button
            loading={isLoading}
            type="submit"
            onClick={() => {}}
            label={sendEmailCashbackLabel}
            disabled={!(isValid && dirty) || isSubmitting}
          />
        </Box>
      </Box>

      {touched.email && Boolean(errors.email) && (
        <AlertMessage
          message={errors.email}
          severity="error"
          className="margin-top14"
        />
      )}
    </Box>
  );

  const noFooterModalPadding = !showFooter &&
    !successFullyReedemed && {
      sx: {
        padding: "62px 24px",
      },
    };

  return (
    <Dialog
      id="cashback-redeem-modal"
      aria-labelledby="customized-dialog-title"
      open={true}
      maxWidth="md"
      className="cashback-redeem-modal"
      disableEscapeKeyDown
      PaperProps={{ className: "cashback-redeem-dialog" }}
    >
      {successFullyReedemed && (
        <Box className="success-cashback-redeem-gif">
          <img
            src={cashbackSuccessRedeemGif}
            className="action-img"
            alt="paid"
          />
        </Box>
      )}
      <DialogContent {...noFooterModalPadding}>
        {isEmailQR ||
          (!showFooter && (
            <IconButton
              className="close-cashback-redeem-modal"
              aria-label="close"
              onClick={
                successFullyReedemed
                  ? handleCloseDialogModal
                  : handleCancelCashback
              }
            >
              <CloseIcon />
            </IconButton>
          ))}
        {successFullyReedemed ? (
          <Box className="success-redeem-screen">
            <Box className="success-cashback-redeem-icon">
              <img src={tickGif} className="action-img" alt="paid" />
            </Box>
            <Text
              align="center"
              className="success-cashback-redeem-amount default-text"
              variant="h4"
              size={24}
            >
              {cashbackAmount}
            </Text>
            <Text
              className="success-cashback-redeem-text default-text"
              align="center"
              size={18}
              font="regular"
              variant="body1"
            >
              {successRedeemText}
            </Text>
          </Box>
        ) : (
          <>
            <Box className="cashback-redeem-icon">
              <img
                src={cashbackRedeemImage}
                alt="check-email"
                height={44}
                width={50}
              />
            </Box>

            <Box className="cashback-congratulation-text">
              <Text size={24} font="semibold" variant="body1">
                {congratulationText}
              </Text>
              <Text size={16} font="medium" sx={{ marginTop: "5px" }}>
                {gotCashbackText}
              </Text>
            </Box>

            <Box className="cashback-redeem-qr">
              <QRCodeComponent
                icon
                iconSize={20}
                value={cashbackQRWithdrawAddress}
                size={185}
              />
            </Box>

            <Box className="cashback-amount-text">
              <Text className="default-text" variant="h4" size={24}>
                {cashbackAmount}
              </Text>
            </Box>
          </>
        )}
      </DialogContent>
      {showFooter && !successFullyReedemed && (
        <DialogActions>
          <Box className="cashback-redeem-modal-footer">
            {isEmailQR ? sendEmailSection : emailQRSection}
          </Box>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CashbackScanModal;
