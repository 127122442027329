export const Countries = [
  {
    id: 1,
    short_name: "Afghanistan",
    iso2: "AF",
    calling_code: "93",
    currency_code: "AFN",
    preferred_currency_code: "AFN",
  },
  {
    id: 2,
    short_name: "Aland Islands",
    iso2: "AX",
    calling_code: "358",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 3,
    short_name: "Albania",
    iso2: "AL",
    calling_code: "355",
    currency_code: "ALL",
    preferred_currency_code: "ALL",
  },
  {
    id: 4,
    short_name: "Algeria",
    iso2: "DZ",
    calling_code: "213",
    currency_code: "DZD",
    preferred_currency_code: "DZD",
  },
  {
    id: 5,
    short_name: "American Samoa",
    iso2: "AS",
    calling_code: "1684",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 6,
    short_name: "Andorra",
    iso2: "AD",
    calling_code: "376",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 7,
    short_name: "Angola",
    iso2: "AO",
    calling_code: "244",
    currency_code: "AOA",
    preferred_currency_code: "AOA",
  },
  {
    id: 8,
    short_name: "Anguilla",
    iso2: "AI",
    calling_code: "1264",
    currency_code: "XCD",
    preferred_currency_code: "XCD",
  },
  // {
  //   id: 9,
  //   short_name: "Antarctica",
  //   iso2: "AQ",
  //   calling_code: "672",
  //   currency_code: "",
  //   preferred_currency_code: "USD"
  // },
  {
    id: 10,
    short_name: "Antigua And Barbuda",
    iso2: "AG",
    calling_code: "1268",
    currency_code: "XCD",
    preferred_currency_code: "XCD",
  },
  {
    id: 11,
    short_name: "Argentina",
    iso2: "AR",
    calling_code: "54",
    currency_code: "ARS",
    preferred_currency_code: "ARS",
  },
  {
    id: 12,
    short_name: "Armenia",
    iso2: "AM",
    calling_code: "374",
    currency_code: "AMD",
    preferred_currency_code: "AMD",
  },
  {
    id: 13,
    short_name: "Aruba",
    iso2: "AW",
    calling_code: "297",
    currency_code: "AWG",
    preferred_currency_code: "AWG",
  },
  {
    id: 14,
    short_name: "Australia",
    iso2: "AU",
    calling_code: "61",
    currency_code: "AUD",
    preferred_currency_code: "AUD",
  },
  {
    id: 15,
    short_name: "Austria",
    iso2: "AT",
    calling_code: "43",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 16,
    short_name: "Azerbaijan",
    iso2: "AZ",
    calling_code: "994",
    currency_code: "AZN",
    preferred_currency_code: "AZN",
  },
  {
    id: 17,
    short_name: "Bahamas The",
    iso2: "BS",
    calling_code: "1242",
    currency_code: "BSD",
    preferred_currency_code: "BSD",
  },
  {
    id: 18,
    short_name: "Bahrain",
    iso2: "BH",
    calling_code: "973",
    currency_code: "BHD",
    preferred_currency_code: "BHD",
  },
  {
    id: 19,
    short_name: "Bangladesh",
    iso2: "BD",
    calling_code: "880",
    currency_code: "BDT",
    preferred_currency_code: "BDT",
  },
  {
    id: 20,
    short_name: "Barbados",
    iso2: "BB",
    calling_code: "1246",
    currency_code: "BBD",
    preferred_currency_code: "BBD",
  },
  {
    id: 21,
    short_name: "Belarus",
    iso2: "BY",
    calling_code: "375",
    currency_code: "BYN",
    preferred_currency_code: "BYN",
  },
  {
    id: 22,
    short_name: "Belgium",
    iso2: "BE",
    calling_code: "32",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 23,
    short_name: "Belize",
    iso2: "BZ",
    calling_code: "501",
    currency_code: "BZD",
    preferred_currency_code: "BZD",
  },
  {
    id: 24,
    short_name: "Benin",
    iso2: "BJ",
    calling_code: "229",
    currency_code: "XOF",
    preferred_currency_code: "XOF",
  },
  {
    id: 25,
    short_name: "Bermuda",
    iso2: "BM",
    calling_code: "1441",
    currency_code: "BMD",
    preferred_currency_code: "BMD",
  },
  {
    id: 26,
    short_name: "Bhutan",
    iso2: "BT",
    calling_code: "975",
    currency_code: "BTN",
    preferred_currency_code: "BTN",
  },
  {
    id: 27,
    short_name: "Bolivia",
    iso2: "BO",
    calling_code: "591",
    currency_code: "BOB",
    preferred_currency_code: "BOB",
  },
  {
    id: 28,
    short_name: "Bosnia and Herzegovina",
    iso2: "BA",
    calling_code: "387",
    currency_code: "BAM",
    preferred_currency_code: "BAM",
  },
  {
    id: 29,
    short_name: "Botswana",
    iso2: "BW",
    calling_code: "267",
    currency_code: "BWP",
    preferred_currency_code: "BWP",
  },
  // {
  //   id: 30,
  //   short_name: "Bouvet Island",
  //   iso2: "BV",
  //   calling_code: "55",
  //   currency_code: "",
  //   preferred_currency_code: "USD"
  // },
  {
    id: 31,
    short_name: "Brazil",
    iso2: "BR",
    calling_code: "55",
    currency_code: "BRL",
    preferred_currency_code: "BRL",
  },
  {
    id: 32,
    short_name: "British Indian Ocean Territory",
    iso2: "IO",
    calling_code: "246",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 33,
    short_name: "Brunei",
    iso2: "BN",
    calling_code: "673",
    currency_code: "BND",
    preferred_currency_code: "BND",
  },
  {
    id: 34,
    short_name: "Bulgaria",
    iso2: "BG",
    calling_code: "359",
    currency_code: "BGN",
    preferred_currency_code: "BGN",
  },
  {
    id: 35,
    short_name: "Burkina Faso",
    iso2: "BF",
    calling_code: "226",
    currency_code: "XOF",
    preferred_currency_code: "XOF",
  },
  {
    id: 36,
    short_name: "Burundi",
    iso2: "BI",
    calling_code: "257",
    currency_code: "BIF",
    preferred_currency_code: "BIF",
  },
  {
    id: 37,
    short_name: "Cambodia",
    iso2: "KH",
    calling_code: "855",
    currency_code: "KHR",
    preferred_currency_code: "KHR",
  },
  {
    id: 38,
    short_name: "Cameroon",
    iso2: "CM",
    calling_code: "237",
    currency_code: "XAF",
    preferred_currency_code: "XAF",
  },
  {
    id: 39,
    short_name: "Canada",
    iso2: "CA",
    calling_code: "1",
    currency_code: "CAD",
    preferred_currency_code: "CAD",
  },
  {
    id: 40,
    short_name: "Cape Verde",
    iso2: "CV",
    calling_code: "238",
    currency_code: "CVE",
    preferred_currency_code: "CVE",
  },
  {
    id: 41,
    short_name: "Cayman Islands",
    iso2: "KY",
    calling_code: "1345",
    currency_code: "KYD",
    preferred_currency_code: "KYD",
  },
  {
    id: 42,
    short_name: "Central African Republic",
    iso2: "CF",
    calling_code: "236",
    currency_code: "XAF",
    preferred_currency_code: "XAF",
  },
  {
    id: 43,
    short_name: "Chad",
    iso2: "TD",
    calling_code: "235",
    currency_code: "XAF",
    preferred_currency_code: "XAF",
  },
  {
    id: 44,
    short_name: "Chile",
    iso2: "CL",
    calling_code: "56",
    currency_code: "CLF",
    preferred_currency_code: "CLF",
  },
  {
    id: 45,
    short_name: "China",
    iso2: "CN",
    calling_code: "86",
    currency_code: "CNY",
    preferred_currency_code: "CNY",
  },
  {
    id: 46,
    short_name: "Christmas Island",
    iso2: "CX",
    calling_code: "61",
    currency_code: "AUD",
    preferred_currency_code: "AUD",
  },
  {
    id: 47,
    short_name: "Cocos (Keeling) Islands",
    iso2: "CC",
    calling_code: "61",
    currency_code: "AUD",
    preferred_currency_code: "AUD",
  },
  {
    id: 48,
    short_name: "Colombia",
    iso2: "CO",
    calling_code: "57",
    currency_code: "COP",
    preferred_currency_code: "COP",
  },
  {
    id: 49,
    short_name: "Comoros",
    iso2: "KM",
    calling_code: "269",
    currency_code: "KMF",
    preferred_currency_code: "KMF",
  },
  {
    id: 50,
    short_name: "Congo",
    iso2: "CG",
    calling_code: "242",
    currency_code: "XAF",
    preferred_currency_code: "XAF",
  },
  {
    id: 51,
    short_name: "Cook Islands",
    iso2: "CK",
    calling_code: "682",
    currency_code: "NZD",
    preferred_currency_code: "NZD",
  },
  {
    id: 52,
    short_name: "Costa Rica",
    iso2: "CR",
    calling_code: "506",
    currency_code: "CRC",
    preferred_currency_code: "CRC",
  },
  {
    id: 53,
    short_name: "Cote D'Ivoire (Ivory Coast)",
    iso2: "CI",
    calling_code: "225",
    currency_code: "XOF",
    preferred_currency_code: "XOF",
  },
  {
    id: 54,
    short_name: "Croatia",
    iso2: "HR",
    calling_code: "385",
    currency_code: "HRK",
    preferred_currency_code: "HRK",
  },
  {
    id: 55,
    short_name: "Cuba",
    iso2: "CU",
    calling_code: "53",
    currency_code: "CUP",
    preferred_currency_code: "CUP",
  },
  {
    id: 56,
    short_name: "Curacao",
    iso2: "CW",
    calling_code: "599",
    currency_code: "ANG",
    preferred_currency_code: "ANG",
  },
  {
    id: 57,
    short_name: "Cyprus",
    iso2: "CY",
    calling_code: "357",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 58,
    short_name: "Czech Republic",
    iso2: "CZ",
    calling_code: "420",
    currency_code: "CZK",
    preferred_currency_code: "CZK",
  },
  {
    id: 59,
    short_name: "Democratic Republic Of the Congo",
    iso2: "CD",
    calling_code: "243",
    currency_code: "CDF",
    preferred_currency_code: "CDF",
  },
  {
    id: 60,
    short_name: "Denmark",
    iso2: "DK",
    calling_code: "45",
    currency_code: "DKK",
    preferred_currency_code: "DKK",
  },
  {
    id: 61,
    short_name: "Djibouti",
    iso2: "DJ",
    calling_code: "253",
    currency_code: "DJF",
    preferred_currency_code: "DJF",
  },
  {
    id: 62,
    short_name: "Dominica",
    iso2: "DM",
    calling_code: "1767",
    currency_code: "XCD",
    preferred_currency_code: "XCD",
  },
  {
    id: 63,
    short_name: "Dominican Republic",
    iso2: "DO",
    calling_code: "1809",
    currency_code: "DOP",
    preferred_currency_code: "DOP",
  },
  {
    id: 64,
    short_name: "Ecuador",
    iso2: "EC",
    calling_code: "593",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 65,
    short_name: "Egypt",
    iso2: "EG",
    calling_code: "20",
    currency_code: "EGP",
    preferred_currency_code: "EGP",
  },
  {
    id: 66,
    short_name: "El Salvador",
    iso2: "SV",
    calling_code: "503",
    currency_code: "SVC",
    preferred_currency_code: "SVC",
  },
  {
    id: 67,
    short_name: "Equatorial Guinea",
    iso2: "GQ",
    calling_code: "240",
    currency_code: "XAF",
    preferred_currency_code: "XAF",
  },
  {
    id: 68,
    short_name: "Eritrea",
    iso2: "ER",
    calling_code: "291",
    currency_code: "ERN",
    preferred_currency_code: "ERN",
  },
  {
    id: 69,
    short_name: "Estonia",
    iso2: "EE",
    calling_code: "372",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 70,
    short_name: "Eswatini",
    iso2: "SZ",
    calling_code: "268",
    currency_code: "SZL",
    preferred_currency_code: "SZL",
  },
  {
    id: 71,
    short_name: "Ethiopia",
    iso2: "ET",
    calling_code: "251",
    currency_code: "ETB",
    preferred_currency_code: "ETB",
  },
  {
    id: 72,
    short_name: "Falkland Islands",
    iso2: "FK",
    calling_code: "500",
    currency_code: "FKP",
    preferred_currency_code: "FKP",
  },
  {
    id: 73,
    short_name: "Faroe Islands",
    iso2: "FO",
    calling_code: "298",
    currency_code: "DKK",
    preferred_currency_code: "DKK",
  },
  {
    id: 74,
    short_name: "Fiji",
    iso2: "FJ",
    calling_code: "679",
    currency_code: "FJD",
    preferred_currency_code: "FJD",
  },
  {
    id: 75,
    short_name: "Finland",
    iso2: "FI",
    calling_code: "358",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 76,
    short_name: "France",
    iso2: "FR",
    calling_code: "33",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 77,
    short_name: "French Guiana",
    iso2: "GF",
    calling_code: "594",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 78,
    short_name: "French Polynesia",
    iso2: "PF",
    calling_code: "689",
    currency_code: "XPF",
    preferred_currency_code: "XPF",
  },
  // {
  //   id: 79,
  //   short_name: "French Southern Territories",
  //   iso2: "TF",
  //   calling_code: "262",
  //   currency_code: "",
  //   preferred_currency_code: "USD"
  // },
  {
    id: 80,
    short_name: "Gabon",
    iso2: "GA",
    calling_code: "241",
    currency_code: "XAF",
    preferred_currency_code: "XAF",
  },
  {
    id: 81,
    short_name: "Gambia The",
    iso2: "GM",
    calling_code: "220",
    currency_code: "GMD",
    preferred_currency_code: "GMD",
  },
  {
    id: 82,
    short_name: "Georgia",
    iso2: "GE",
    calling_code: "995",
    currency_code: "GEL",
    preferred_currency_code: "GEL",
  },
  {
    id: 83,
    short_name: "Germany",
    iso2: "DE",
    calling_code: "49",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 84,
    short_name: "Ghana",
    iso2: "GH",
    calling_code: "233",
    currency_code: "GHS",
    preferred_currency_code: "GHS",
  },
  {
    id: 85,
    short_name: "Gibraltar",
    iso2: "GI",
    calling_code: "350",
    currency_code: "GIP",
    preferred_currency_code: "GIP",
  },
  {
    id: 86,
    short_name: "Greece",
    iso2: "GR",
    calling_code: "30",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 87,
    short_name: "Greenland",
    iso2: "GL",
    calling_code: "299",
    currency_code: "DKK",
    preferred_currency_code: "DKK",
  },
  {
    id: 88,
    short_name: "Grenada",
    iso2: "GD",
    calling_code: "1473",
    currency_code: "XCD",
    preferred_currency_code: "XCD",
  },
  {
    id: 89,
    short_name: "Guadeloupe",
    iso2: "GP",
    calling_code: "590",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 90,
    short_name: "Guam",
    iso2: "GU",
    calling_code: "1671",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 91,
    short_name: "Guatemala",
    iso2: "GT",
    calling_code: "502",
    currency_code: "GTQ",
    preferred_currency_code: "GTQ",
  },
  {
    id: 92,
    short_name: "Guernsey",
    iso2: "GG",
    calling_code: "1481",
    currency_code: "GBP",
    preferred_currency_code: "GBP",
  },
  {
    id: 93,
    short_name: "Guinea",
    iso2: "GN",
    calling_code: "224",
    currency_code: "GNF",
    preferred_currency_code: "GNF",
  },
  {
    id: 94,
    short_name: "Guinea-Bissau",
    iso2: "GW",
    calling_code: "245",
    currency_code: "XOF",
    preferred_currency_code: "XOF",
  },
  {
    id: 95,
    short_name: "Guyana",
    iso2: "GY",
    calling_code: "592",
    currency_code: "GYD",
    preferred_currency_code: "GYD",
  },
  {
    id: 96,
    short_name: "Haiti",
    iso2: "HT",
    calling_code: "509",
    currency_code: "HTG",
    preferred_currency_code: "HTG",
  },
  // {
  //   id: 97,
  //   short_name: "Heard and McDonald Islands",
  //   iso2: "HM",
  //   calling_code: "672",
  //   currency_code: "",
  //   preferred_currency_code: "USD"
  // },
  {
    id: 98,
    short_name: "Honduras",
    iso2: "HN",
    calling_code: "504",
    currency_code: "HNL",
    preferred_currency_code: "HNL",
  },
  {
    id: 99,
    short_name: "Hong Kong",
    iso2: "HK",
    calling_code: "852",
    currency_code: "HKD",
    preferred_currency_code: "HKD",
  },
  {
    id: 100,
    short_name: "Hungary",
    iso2: "HU",
    calling_code: "36",
    currency_code: "HUF",
    preferred_currency_code: "HUF",
  },
  {
    id: 101,
    short_name: "Iceland",
    iso2: "IS",
    calling_code: "354",
    currency_code: "ISK",
    preferred_currency_code: "ISK",
  },
  {
    id: 102,
    short_name: "India",
    iso2: "IN",
    calling_code: "91",
    currency_code: "INR",
    preferred_currency_code: "INR",
  },
  {
    id: 103,
    short_name: "Indonesia",
    iso2: "ID",
    calling_code: "62",
    currency_code: "IDR",
    preferred_currency_code: "IDR",
  },
  {
    id: 104,
    short_name: "Iran",
    iso2: "IR",
    calling_code: "98",
    currency_code: "IRR",
    preferred_currency_code: "IRR",
  },
  {
    id: 105,
    short_name: "Iraq",
    iso2: "IQ",
    calling_code: "964",
    currency_code: "IQD",
    preferred_currency_code: "IQD",
  },
  {
    id: 106,
    short_name: "Ireland",
    iso2: "IE",
    calling_code: "353",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 107,
    short_name: "Isle of Man",
    iso2: "IM",
    calling_code: "44",
    currency_code: "GBP",
    preferred_currency_code: "GBP",
  },
  {
    id: 108,
    short_name: "Israel",
    iso2: "IL",
    calling_code: "972",
    currency_code: "ILS",
    preferred_currency_code: "ILS",
  },
  {
    id: 109,
    short_name: "Italy",
    iso2: "IT",
    calling_code: "39",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 110,
    short_name: "Jamaica",
    iso2: "JM",
    calling_code: "1876",
    currency_code: "JMD",
    preferred_currency_code: "JMD",
  },
  {
    id: 111,
    short_name: "Japan",
    iso2: "JP",
    calling_code: "81",
    currency_code: "JPY",
    preferred_currency_code: "JPY",
  },
  {
    id: 112,
    short_name: "Jersey",
    iso2: "JE",
    calling_code: "44",
    currency_code: "GBP",
    preferred_currency_code: "GBP",
  },
  {
    id: 113,
    short_name: "Jordan",
    iso2: "JO",
    calling_code: "962",
    currency_code: "JOD",
    preferred_currency_code: "JOD",
  },
  {
    id: 114,
    short_name: "Kazakhstan",
    iso2: "KZ",
    calling_code: "7",
    currency_code: "KZT",
    preferred_currency_code: "KZT",
  },
  {
    id: 115,
    short_name: "Kenya",
    iso2: "KE",
    calling_code: "254",
    currency_code: "KES",
    preferred_currency_code: "KES",
  },
  {
    id: 116,
    short_name: "Kiribati",
    iso2: "KI",
    calling_code: "686",
    currency_code: "AUD",
    preferred_currency_code: "AUD",
  },
  {
    id: 117,
    short_name: "Korea North",
    iso2: "KP",
    calling_code: "850",
    currency_code: "KPW",
    preferred_currency_code: "KPW",
  },
  {
    id: 118,
    short_name: "Korea South",
    iso2: "KR",
    calling_code: "82",
    currency_code: "KRW",
    preferred_currency_code: "KRW",
  },
  {
    id: 119,
    short_name: "Kuwait",
    iso2: "KW",
    calling_code: "965",
    currency_code: "KWD",
    preferred_currency_code: "KWD",
  },
  {
    id: 120,
    short_name: "Kyrgyzstan",
    iso2: "KG",
    calling_code: "996",
    currency_code: "KGS",
    preferred_currency_code: "KGS",
  },
  {
    id: 121,
    short_name: "Laos",
    iso2: "LA",
    calling_code: "856",
    currency_code: "LAK",
    preferred_currency_code: "LAK",
  },
  {
    id: 122,
    short_name: "Latvia",
    iso2: "LV",
    calling_code: "371",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 123,
    short_name: "Lebanon",
    iso2: "LB",
    calling_code: "961",
    currency_code: "LBP",
    preferred_currency_code: "LBP",
  },
  {
    id: 124,
    short_name: "Lesotho",
    iso2: "LS",
    calling_code: "266",
    currency_code: "LSL",
    preferred_currency_code: "LSL",
  },
  {
    id: 125,
    short_name: "Liberia",
    iso2: "LR",
    calling_code: "231",
    currency_code: "LRD",
    preferred_currency_code: "LRD",
  },
  {
    id: 126,
    short_name: "Libya",
    iso2: "LY",
    calling_code: "218",
    currency_code: "LYD",
    preferred_currency_code: "LYD",
  },
  {
    id: 127,
    short_name: "Liechtenstein",
    iso2: "LI",
    calling_code: "423",
    currency_code: "CHF",
    preferred_currency_code: "CHF",
  },
  {
    id: 128,
    short_name: "Lithuania",
    iso2: "LT",
    calling_code: "370",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 129,
    short_name: "Luxembourg",
    iso2: "LU",
    calling_code: "352",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 130,
    short_name: "Macao S.A.R",
    iso2: "MO",
    calling_code: "853",
    currency_code: "MOP",
    preferred_currency_code: "MOP",
  },
  {
    id: 131,
    short_name: "Macedonia",
    iso2: "MK",
    calling_code: "389",
    currency_code: "MKD",
    preferred_currency_code: "MKD",
  },
  {
    id: 132,
    short_name: "Madagascar",
    iso2: "MG",
    calling_code: "261",
    currency_code: "MGA",
    preferred_currency_code: "MGA",
  },
  {
    id: 133,
    short_name: "Malawi",
    iso2: "MW",
    calling_code: "265",
    currency_code: "MWK",
    preferred_currency_code: "MWK",
  },
  {
    id: 134,
    short_name: "Malaysia",
    iso2: "MY",
    calling_code: "60",
    currency_code: "MYR",
    preferred_currency_code: "MYR",
  },
  {
    id: 135,
    short_name: "Maldives",
    iso2: "MV",
    calling_code: "960",
    currency_code: "MVR",
    preferred_currency_code: "MVR",
  },
  {
    id: 136,
    short_name: "Mali",
    iso2: "ML",
    calling_code: "223",
    currency_code: "XOF",
    preferred_currency_code: "XOF",
  },
  {
    id: 137,
    short_name: "Malta",
    iso2: "MT",
    calling_code: "356",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 138,
    short_name: "Marshall Islands",
    iso2: "MH",
    calling_code: "692",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 139,
    short_name: "Martinique",
    iso2: "MQ",
    calling_code: "596",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 140,
    short_name: "Mauritania",
    iso2: "MR",
    calling_code: "222",
    currency_code: "MRO",
    preferred_currency_code: "MRO",
  },
  {
    id: 141,
    short_name: "Mauritius",
    iso2: "MU",
    calling_code: "230",
    currency_code: "MUR",
    preferred_currency_code: "MUR",
  },
  {
    id: 142,
    short_name: "Mayotte",
    iso2: "YT",
    calling_code: "262",
    currency_code: "",
    preferred_currency_code: "USD",
  },
  {
    id: 143,
    short_name: "Mexico",
    iso2: "MX",
    calling_code: "52",
    currency_code: "MXN",
    preferred_currency_code: "MXN",
  },
  {
    id: 144,
    short_name: "Micronesia",
    iso2: "FM",
    calling_code: "691",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 145,
    short_name: "Moldova",
    iso2: "MD",
    calling_code: "373",
    currency_code: "MDL",
    preferred_currency_code: "MDL",
  },
  {
    id: 146,
    short_name: "Monaco",
    iso2: "MC",
    calling_code: "377",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 147,
    short_name: "Mongolia",
    iso2: "MN",
    calling_code: "976",
    currency_code: "MNT",
    preferred_currency_code: "MNT",
  },
  {
    id: 148,
    short_name: "Montenegro",
    iso2: "ME",
    calling_code: "382",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 149,
    short_name: "Montserrat",
    iso2: "MS",
    calling_code: "1664",
    currency_code: "XCD",
    preferred_currency_code: "XCD",
  },
  {
    id: 150,
    short_name: "Morocco",
    iso2: "MA",
    calling_code: "212",
    currency_code: "MAD",
    preferred_currency_code: "MAD",
  },
  {
    id: 151,
    short_name: "Mozambique",
    iso2: "MZ",
    calling_code: "258",
    currency_code: "MZN",
    preferred_currency_code: "MZN",
  },
  {
    id: 152,
    short_name: "Myanmar",
    iso2: "MM",
    calling_code: "95",
    currency_code: "MMK",
    preferred_currency_code: "MMK",
  },
  {
    id: 153,
    short_name: "Namibia",
    iso2: "NA",
    calling_code: "264",
    currency_code: "NAD",
    preferred_currency_code: "NAD",
  },
  {
    id: 154,
    short_name: "Nauru",
    iso2: "NR",
    calling_code: "674",
    currency_code: "AUD",
    preferred_currency_code: "AUD",
  },
  {
    id: 155,
    short_name: "Nepal",
    iso2: "NP",
    calling_code: "977",
    currency_code: "NPR",
    preferred_currency_code: "NPR",
  },
  {
    id: 156,
    short_name: "Netherlands",
    iso2: "NL",
    calling_code: "31",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 157,
    short_name: "New Caledonia",
    iso2: "NC",
    calling_code: "687",
    currency_code: "XPF",
    preferred_currency_code: "XPF",
  },
  {
    id: 158,
    short_name: "New Zealand",
    iso2: "NZ",
    calling_code: "64",
    currency_code: "NZD",
    preferred_currency_code: "NZD",
  },
  {
    id: 159,
    short_name: "Nicaragua",
    iso2: "NI",
    calling_code: "505",
    currency_code: "NIO",
    preferred_currency_code: "NIO",
  },
  {
    id: 160,
    short_name: "Niger",
    iso2: "NE",
    calling_code: "227",
    currency_code: "XOF",
    preferred_currency_code: "XOF",
  },
  {
    id: 161,
    short_name: "Nigeria",
    iso2: "NG",
    calling_code: "234",
    currency_code: "NGN",
    preferred_currency_code: "NGN",
  },
  {
    id: 162,
    short_name: "Niue",
    iso2: "NU",
    calling_code: "683",
    currency_code: "NZD",
    preferred_currency_code: "NZD",
  },
  {
    id: 163,
    short_name: "Norfolk Island",
    iso2: "NF",
    calling_code: "672",
    currency_code: "",
    preferred_currency_code: "USD",
  },
  {
    id: 164,
    short_name: "Northern Mariana Islands",
    iso2: "MP",
    calling_code: "1670",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 165,
    short_name: "Norway",
    iso2: "NO",
    calling_code: "47",
    currency_code: "NOK",
    preferred_currency_code: "NOK",
  },
  {
    id: 166,
    short_name: "Oman",
    iso2: "OM",
    calling_code: "968",
    currency_code: "OMR",
    preferred_currency_code: "OMR",
  },
  {
    id: 167,
    short_name: "Pakistan",
    iso2: "PK",
    calling_code: "92",
    currency_code: "PKR",
    preferred_currency_code: "PKR",
  },
  {
    id: 168,
    short_name: "Palau",
    iso2: "PW",
    calling_code: "680",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 169,
    short_name: "Palestinian Territory Occupied",
    iso2: "PS",
    calling_code: "970",
    currency_code: "",
    preferred_currency_code: "USD",
  },
  {
    id: 170,
    short_name: "Panama",
    iso2: "PA",
    calling_code: "507",
    currency_code: "PAB",
    preferred_currency_code: "PAB",
  },
  {
    id: 171,
    short_name: "Papua new Guinea",
    iso2: "PG",
    calling_code: "675",
    currency_code: "PGK",
    preferred_currency_code: "PGK",
  },
  {
    id: 172,
    short_name: "Paraguay",
    iso2: "PY",
    calling_code: "595",
    currency_code: "PYG",
    preferred_currency_code: "PYG",
  },
  {
    id: 173,
    short_name: "Peru",
    iso2: "PE",
    calling_code: "51",
    currency_code: "PEN",
    preferred_currency_code: "PEN",
  },
  {
    id: 174,
    short_name: "Philippines",
    iso2: "PH",
    calling_code: "63",
    currency_code: "PHP",
    preferred_currency_code: "PHP",
  },
  {
    id: 175,
    short_name: "Pitcairn Island",
    iso2: "PN",
    calling_code: "64",
    currency_code: "NZD",
    preferred_currency_code: "NZD",
  },
  {
    id: 176,
    short_name: "Poland",
    iso2: "PL",
    calling_code: "48",
    currency_code: "PLN",
    preferred_currency_code: "PLN",
  },
  {
    id: 177,
    short_name: "Portugal",
    iso2: "PT",
    calling_code: "351",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 178,
    short_name: "Puerto Rico",
    iso2: "PR",
    calling_code: "1939",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 179,
    short_name: "Qatar",
    iso2: "QA",
    calling_code: "974",
    currency_code: "QAR",
    preferred_currency_code: "QAR",
  },
  {
    id: 180,
    short_name: "Reunion",
    iso2: "RE",
    calling_code: "262",
    currency_code: "",
    preferred_currency_code: "USD",
  },
  {
    id: 181,
    short_name: "Romania",
    iso2: "RO",
    calling_code: "40",
    currency_code: "RON",
    preferred_currency_code: "RON",
  },
  {
    id: 182,
    short_name: "Russia",
    iso2: "RU",
    calling_code: "7",
    currency_code: "RUB",
    preferred_currency_code: "RUB",
  },
  {
    id: 183,
    short_name: "Rwanda",
    iso2: "RW",
    calling_code: "250",
    currency_code: "RWF",
    preferred_currency_code: "RWF",
  },
  {
    id: 184,
    short_name: "Saint Helena",
    iso2: "SH",
    calling_code: "290",
    currency_code: "SHP",
    preferred_currency_code: "SHP",
  },
  {
    id: 185,
    short_name: "Saint Kitts And Nevis",
    iso2: "KN",
    calling_code: "1869",
    currency_code: "XCD",
    preferred_currency_code: "XCD",
  },
  {
    id: 186,
    short_name: "Saint Lucia",
    iso2: "LC",
    calling_code: "1758",
    currency_code: "XCD",
    preferred_currency_code: "XCD",
  },
  {
    id: 187,
    short_name: "Saint Pierre and Miquelon",
    iso2: "PM",
    calling_code: "508",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 188,
    short_name: "Saint Vincent And The Grenadines",
    iso2: "VC",
    calling_code: "1784",
    currency_code: "XCD",
    preferred_currency_code: "XCD",
  },
  {
    id: 189,
    short_name: "Samoa",
    iso2: "WS",
    calling_code: "685",
    currency_code: "WST",
    preferred_currency_code: "WST",
  },
  {
    id: 190,
    short_name: "San Marino",
    iso2: "SM",
    calling_code: "378",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 191,
    short_name: "Sao Tome and Principe",
    iso2: "ST",
    calling_code: "239",
    currency_code: "STD",
    preferred_currency_code: "STD",
  },
  {
    id: 192,
    short_name: "Saudi Arabia",
    iso2: "SA",
    calling_code: "966",
    currency_code: "SAR",
    preferred_currency_code: "SAR",
  },
  {
    id: 193,
    short_name: "Senegal",
    iso2: "SN",
    calling_code: "221",
    currency_code: "XOF",
    preferred_currency_code: "XOF",
  },
  {
    id: 194,
    short_name: "Serbia",
    iso2: "RS",
    calling_code: "381",
    currency_code: "RSD",
    preferred_currency_code: "RSD",
  },
  {
    id: 195,
    short_name: "Seychelles",
    iso2: "SC",
    calling_code: "248",
    currency_code: "SCR",
    preferred_currency_code: "SCR",
  },
  {
    id: 196,
    short_name: "Sierra Leone",
    iso2: "SL",
    calling_code: "232",
    currency_code: "SLL",
    preferred_currency_code: "SLL",
  },
  {
    id: 197,
    short_name: "Singapore",
    iso2: "SG",
    calling_code: "65",
    currency_code: "SGD",
    preferred_currency_code: "SGD",
  },
  {
    id: 198,
    short_name: "Sint Maarten",
    iso2: "SX",
    calling_code: "721",
    currency_code: "ANG",
    preferred_currency_code: "ANG",
  },
  {
    id: 199,
    short_name: "Slovakia",
    iso2: "SK",
    calling_code: "421",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 200,
    short_name: "Slovenia",
    iso2: "SI",
    calling_code: "386",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 201,
    short_name: "Solomon Islands",
    iso2: "SB",
    calling_code: "677",
    currency_code: "SBD",
    preferred_currency_code: "SBD",
  },
  {
    id: 202,
    short_name: "Somalia",
    iso2: "SO",
    calling_code: "252",
    currency_code: "SOS",
    preferred_currency_code: "SOS",
  },
  {
    id: 203,
    short_name: "South Africa",
    iso2: "ZA",
    calling_code: "27",
    currency_code: "ZAR",
    preferred_currency_code: "ZAR",
  },
  {
    id: 204,
    short_name: "South Georgia",
    iso2: "GS",
    calling_code: "500",
    currency_code: "",
    preferred_currency_code: "USD",
  },
  {
    id: 205,
    short_name: "South Sudan",
    iso2: "SS",
    calling_code: "211",
    currency_code: "SSP",
    preferred_currency_code: "SSP",
  },
  {
    id: 206,
    short_name: "Spain",
    iso2: "ES",
    calling_code: "34",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 207,
    short_name: "Sri Lanka",
    iso2: "LK",
    calling_code: "94",
    currency_code: "LKR",
    preferred_currency_code: "LKR",
  },
  {
    id: 208,
    short_name: "Sudan",
    iso2: "SD",
    calling_code: "249",
    currency_code: "SDG",
    preferred_currency_code: "SDG",
  },
  {
    id: 209,
    short_name: "Suriname",
    iso2: "SR",
    calling_code: "597",
    currency_code: "SRD",
    preferred_currency_code: "SRD",
  },
  {
    id: 210,
    short_name: "Svalbard And Jan Mayen",
    iso2: "SJ",
    calling_code: "47",
    currency_code: "NOK",
    preferred_currency_code: "NOK",
  },
  {
    id: 211,
    short_name: "Sweden",
    iso2: "SE",
    calling_code: "46",
    currency_code: "SEK",
    preferred_currency_code: "SEK",
  },
  {
    id: 212,
    short_name: "Switzerland",
    iso2: "CH",
    calling_code: "41",
    currency_code: "CHF",
    preferred_currency_code: "CHF",
  },
  {
    id: 213,
    short_name: "Syria",
    iso2: "SY",
    calling_code: "963",
    currency_code: "SYP",
    preferred_currency_code: "SYP",
  },
  {
    id: 214,
    short_name: "Taiwan",
    iso2: "TW",
    calling_code: "886",
    currency_code: "TWD",
    preferred_currency_code: "TWD",
  },
  {
    id: 215,
    short_name: "Tajikistan",
    iso2: "TJ",
    calling_code: "992",
    currency_code: "TJS",
    preferred_currency_code: "TJS",
  },
  {
    id: 216,
    short_name: "Tanzania",
    iso2: "TZ",
    calling_code: "255",
    currency_code: "TZS",
    preferred_currency_code: "TZS",
  },
  {
    id: 217,
    short_name: "Thailand",
    iso2: "TH",
    calling_code: "66",
    currency_code: "THB",
    preferred_currency_code: "THB",
  },
  {
    id: 218,
    short_name: "Timor-Leste",
    iso2: "TL",
    calling_code: "670",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 219,
    short_name: "Togo",
    iso2: "TG",
    calling_code: "228",
    currency_code: "XOF",
    preferred_currency_code: "XOF",
  },
  {
    id: 220,
    short_name: "Tokelau",
    iso2: "TK",
    calling_code: "690",
    currency_code: "NZD",
    preferred_currency_code: "NZD",
  },
  {
    id: 221,
    short_name: "Tonga",
    iso2: "TO",
    calling_code: "676",
    currency_code: "TOP",
    preferred_currency_code: "TOP",
  },
  {
    id: 222,
    short_name: "Trinidad And Tobago",
    iso2: "TT",
    calling_code: "1868",
    currency_code: "TTD",
    preferred_currency_code: "TTD",
  },
  {
    id: 223,
    short_name: "Tunisia",
    iso2: "TN",
    calling_code: "216",
    currency_code: "TND",
    preferred_currency_code: "TND",
  },
  {
    id: 224,
    short_name: "Turkey",
    iso2: "TR",
    calling_code: "90",
    currency_code: "TRY",
    preferred_currency_code: "TRY",
  },
  {
    id: 225,
    short_name: "Turkmenistan",
    iso2: "TM",
    calling_code: "993",
    currency_code: "TMT",
    preferred_currency_code: "TMT",
  },
  {
    id: 226,
    short_name: "Turks And Caicos Islands",
    iso2: "TC",
    calling_code: "1649",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 227,
    short_name: "Tuvalu",
    iso2: "TV",
    calling_code: "688",
    currency_code: "AUD",
    preferred_currency_code: "AUD",
  },
  {
    id: 228,
    short_name: "Uganda",
    iso2: "UG",
    calling_code: "256",
    currency_code: "UGX",
    preferred_currency_code: "UGX",
  },
  {
    id: 229,
    short_name: "Ukraine",
    iso2: "UA",
    calling_code: "380",
    currency_code: "UAH",
    preferred_currency_code: "UAH",
  },
  {
    id: 230,
    short_name: "United Arab Emirates",
    iso2: "AE",
    calling_code: "971",
    currency_code: "AED",
    preferred_currency_code: "AED",
  },
  {
    id: 231,
    short_name: "United Kingdom",
    iso2: "GB",
    calling_code: "44",
    currency_code: "GBP",
    preferred_currency_code: "GBP",
  },
  {
    id: 232,
    short_name: "United States",
    iso2: "US",
    calling_code: "1",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 233,
    short_name: "United States Minor Outlying Islands",
    iso2: "UM",
    calling_code: "246",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 234,
    short_name: "Uruguay",
    iso2: "UY",
    calling_code: "598",
    currency_code: "UYU",
    preferred_currency_code: "UYU",
  },
  {
    id: 235,
    short_name: "Uzbekistan",
    iso2: "UZ",
    calling_code: "998",
    currency_code: "UZS",
    preferred_currency_code: "UZS",
  },
  {
    id: 236,
    short_name: "Vanuatu",
    iso2: "VU",
    calling_code: "678",
    currency_code: "VUV",
    preferred_currency_code: "VUV",
  },
  {
    id: 237,
    short_name: "Vatican City State (Holy See)",
    iso2: "VA",
    calling_code: "39",
    currency_code: "EUR",
    preferred_currency_code: "EUR",
  },
  {
    id: 238,
    short_name: "Venezuela",
    iso2: "VE",
    calling_code: "58",
    currency_code: "VEF",
    preferred_currency_code: "VEF",
  },
  {
    id: 239,
    short_name: "Vietnam",
    iso2: "VN",
    calling_code: "84",
    currency_code: "VND",
    preferred_currency_code: "VND",
  },
  {
    id: 240,
    short_name: "Virgin Islands (British)",
    iso2: "VG",
    calling_code: "1284",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 241,
    short_name: "Virgin Islands (US)",
    iso2: "VI",
    calling_code: "1340",
    currency_code: "USD",
    preferred_currency_code: "USD",
  },
  {
    id: 242,
    short_name: "Wallis And Futuna Islands",
    iso2: "WF",
    calling_code: "681",
    currency_code: "XPF",
    preferred_currency_code: "XPF",
  },
  {
    id: 243,
    short_name: "Western Sahara",
    iso2: "EH",
    calling_code: "212",
    currency_code: "MAD",
    preferred_currency_code: "MAD",
  },
  {
    id: 244,
    short_name: "Yemen",
    iso2: "YE",
    calling_code: "967",
    currency_code: "YER",
    preferred_currency_code: "YER",
  },
  {
    id: 245,
    short_name: "Zambia",
    iso2: "ZM",
    calling_code: "260",
    currency_code: "ZMW",
    preferred_currency_code: "ZMW",
  },
  {
    id: 246,
    short_name: "Zimbabwe",
    iso2: "ZW",
    calling_code: "263",
    currency_code: "ZWL",
    preferred_currency_code: "ZWL",
  },
];
