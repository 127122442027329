import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import { CustomTooltip } from "../Tooltip/Tooltip";
import "./clipboard.scss";

const Clipboard = ({ text, ...props }) => {
  const [copied, setCopied] = useState(false);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      navigator?.clipboard?.writeText(text);
      handleCopy();
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  useEffect(async () => {
    if (text && props.from === "withdrawal-link" && !props.passwordCheckState) {
      await navigator?.clipboard?.writeText(text);
      handleCopy();
    }
  }, [text]);

  return (
    <CopyToClipboard
      text={text}
      style={{ cursor: "pointer" }}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      {...props}
      onCopy={() => {
        handleCopy();
      }}
    >
      <CustomTooltip
        arrow={false}
        open={copied}
        placement={"top"}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        text={
          <>
            <CheckCircleSharpIcon />
            <span className="copied-clipboard-text">Copied</span>
          </>
        }
      >
        <FileCopyIcon className="file-copy-icon" />
      </CustomTooltip>
    </CopyToClipboard>
  );
};

const propTypes = {
  text: PropTypes.string.isRequired,
  onCopy: PropTypes.func,
};

Clipboard.propTypes = propTypes;

export default Clipboard;
