import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./latest-web-design.scss";
import LeftSection from "./LeftSection";
import RightSection from "./RightSection";
import {
  getBrandingTheme,
  getCommonAllProps,
  renderFrequentComponent,
} from "../constants";
import CashbackScanModal from "../CashbackScanModal";
import { WebPreviewContext } from "../../contexts/contexts";

const LatestWebDesign = (props) => {
  const {
    paymentDetails,
    brandingPageUI,
    isShowCashbackRedeemModal,
    setIsShowCashbackRedeemModal,
    setRedirectToSuccessURL,
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    addressType,
    webViewProps,
    showPageLoader,
    appSidePreviewClassName = "",
  } = props;

  const [fontFamily, setFontFamily] = useState("system-ui");
  const [textColor, setTextColor] = useState("#fff");
  const [loader, setLoader] = useState(false);
  const [toggleFlag, setToggleFlag] = useState(false);
  const [selectedAssetCurrency, setSelectedAssetCurrency] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [paymentDetailsObj, setPaymentDetailsObj] = useState();
  const [assetAmountLoader, setAssetAmountLoader] = useState(false);
  const [assetQrLoader, setAssetQrLoader] = useState(false);

  const { additionalProps, isPartialPaymentAvailable } = getCommonAllProps({
    fontFamily,
    textColor,
    toggleFlag,
    loader,
    setToggleFlag,
    visibleInPaymentPage: webViewProps?.visibleInPaymentPage,
    displayAmountInCurrency:
      webViewProps?.paymentPageSettings?.display_amount_in_currency,
    selectedAssetCurrency,
    paymentDetailsObj,
    ...props,
  });

  const checkoutSessionId = webViewProps?.checkoutSessionId;

  useEffect(() => {
    getBrandingTheme(brandingPageUI, setLoader, setFontFamily, setTextColor);
  }, [brandingPageUI]);

  useEffect(() => {
    if (
      webViewProps?.paymentPageSettings?.display_amount_in_currency?.toLowerCase() ===
      "sats"
    ) {
      setToggleFlag(true);
    }
    if (
      webViewProps?.paymentPageSettings?.display_amount_in_currency?.toLowerCase() ===
      "btc"
    ) {
      setToggleFlag(false);
    }

    if (selectedAssetCurrency && selectedAssetCurrency !== "SATS") {
      setToggleFlag(true);
    }
  }, [webViewProps?.paymentPageSettings, selectedAssetCurrency]);

  const multiAssetProps = {
    selectedAssetCurrency,
    setSelectedAssetCurrency,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentDetailsObj,
    setPaymentDetailsObj,
    assetAmountLoader,
    setAssetAmountLoader,
    assetQrLoader,
    setAssetQrLoader,
  };

  const passingProps = {
    ...additionalProps,
    ...props,
    ...multiAssetProps,
    isPartialPaymentAvailable,
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    addressType,
    checkoutSessionId,
  };

  return (
    <WebPreviewContext.Provider value={webViewProps}>
      <Box
        className={`latest-web-design-wrapper ${appSidePreviewClassName}`}
        sx={{
          minHeight: webViewProps?.visibleInPaymentPage ? "100vh" : "80vh",
        }}
      >
        {showPageLoader && loader ? (
          renderFrequentComponent("pageLoader", passingProps)
        ) : (
          <>
            <LeftSection {...passingProps} />
            <RightSection {...passingProps} />

            {isShowCashbackRedeemModal && (
              <CashbackScanModal
                handleCloseCashbackModal={() => {
                  if (setIsShowCashbackRedeemModal)
                    setIsShowCashbackRedeemModal(false);
                }}
                cashbackDetails={paymentDetails?.cashback}
                setRedirectToSuccessURL={setRedirectToSuccessURL}
                checkoutSessionId={checkoutSessionId}
              />
            )}
          </>
        )}
      </Box>
    </WebPreviewContext.Provider>
  );
};

export default LatestWebDesign;
