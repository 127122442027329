import React from "react";
import { Box } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { pageTitleDesc, pageTitleLabel } from "../messages";
import HeaderAmountSection from "./HeaderAmountSection";
import PaymentDetailDescription from "./PaymentDetailDescription";
import PaymentDetailTitle from "./PaymentDetailTitle";

const PageTIDPreview = (props) => {
  const { textStyle, paymentInfo, visibleInPaymentPage } = props;

  const desc = paymentInfo?.details?.pageDescription;
  const pageTitle = paymentInfo?.details?.pageTitle || pageTitleLabel;
  const pageDescription = !visibleInPaymentPage ? desc || pageTitleDesc : desc;
  const pageImage = paymentInfo?.details?.image || "";

  return (
    <Box className="new-page-tid-preview">
      <PaymentDetailTitle
        pageTitle={pageTitle}
        textProps={{
          fontWeight: 500,
          fontFamily: textStyle.fontFamily,
          marginTop: "18px",
          color: textStyle.color,
          lineHeight: "140%",
          marginBottom: "10px",
        }}
      />
      <HeaderAmountSection {...props} resolution="web" />
      <Box>
        {pageImage ? (
          <img src={pageImage} className="pl-preview-image" />
        ) : (
          !visibleInPaymentPage && (
            <Box component="div" className="image-upload-box">
              <ImageIcon className="image-icon" />
            </Box>
          )
        )}
      </Box>
      {pageDescription && (
        <PaymentDetailDescription
          boxStyle={{ marginTop: "16px" }}
          pageDescription={pageDescription}
          textProps={{
            fontFamily: textStyle.fontFamily,
            color: textStyle.color,
          }}
        />
      )}
    </Box>
  );
};

export default PageTIDPreview;
