import React from "react";
import { Drawer, Box } from "@mui/material";
import Text from "./Text/Text";
import CustomDivider from "./Divider/Divider";
import Translation from "./Translation";
import CloseIcon from "@mui/icons-material/Close";
import InvoicePageProductsModal from "./InvoicePageProductsModal";

const PaymentDetailDrawer = ({
  openPaymentDetailDrawer,
  handleCloseDrawer,
  fontFamily,
  paymentInfo,
  products,
}) => {
  const titleFontStyle = { fontFamily, fontWeight: 600 };

  const drawerHeader = () => {
    return (
      <Box className="drawer-header">
        <Text
          size={18}
          variant="h5"
          className="default-text"
          style={titleFontStyle}
        >
          <Translation value="invoiceDetail" />
        </Text>
        <Box
          height={20}
          width={20}
          onClick={handleCloseDrawer}
          component="span"
          className="pointer-cursor"
        >
          <CloseIcon sx={{ height: 20, width: 20 }} htmlColor="#848b9e" />
        </Box>
      </Box>
    );
  };

  const drawerBodyContent = () => {
    return (
      <InvoicePageProductsModal
        isShowDivider={true}
        paymentInfo={paymentInfo}
        fontFamily={fontFamily}
        products={products}
        isShowItemsHeader={true}
        isShowTotal={true}
      />
    );
  };
  return (
    <Drawer
      id="checkout-help-drawer"
      className="checkout-drawer"
      anchor="right"
      open={openPaymentDetailDrawer}
      onClose={handleCloseDrawer}
    >
      <Box className="drawer-wrapper" role="presentation">
        {drawerHeader()}
        <CustomDivider />
        <Box className="help-body-content">{drawerBodyContent()}</Box>
      </Box>
    </Drawer>
  );
};

export default PaymentDetailDrawer;
