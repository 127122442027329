import React, { forwardRef, useState } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import Label from "@speed/common/src/components/Label/Label";
import "./mask-input.scss";
import { Box } from "@mui/material";
import classNames from "classnames";
import { CustomPopper } from "../Popper/Popper";
import ErrorIcon from "@mui/icons-material/Error";

const InputMask = forwardRef((props, ref) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    value,
    onChange,
    error,
    label,
    placeholder,
    allowDecimal = true,
    showTypeBox = false,
    typeValue = "Value",
    typeBoxWidth = "100px",
    showLabelInfoPopup,
    popupContent,
    numOfAllowedDigits,
    numOfAllowedIntegerDigits,
    extraClass = "",
    customIcon,
    endInputIconElement = null,
  } = props;

  const customDigits =
    allowDecimal && numOfAllowedDigits ? numOfAllowedDigits : 16;

  const defaultMaskOptions = {
    includeThousandsSeparator: false,
    allowDecimal: allowDecimal,
    decimalSymbol: ".",
    decimalLimit: customDigits, // how many digits allowed after the decimal
    integerLimit: numOfAllowedIntegerDigits || customDigits, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
    prefix: "",
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...{},
  });

  const classes = classNames(
    "mask-input",
    error ? "mask-input-error" : "",
    props.disabled ? "mask-input-disabled" : "",
    extraClass
  );

  const IconElement = customIcon || ErrorIcon;
  return (
    <>
      <Box display="flex">
        {label && <Label>{label}</Label>}
        {showLabelInfoPopup && (
          <Box position="relative" bottom="1px" marginLeft="5px">
            <IconElement
              onClick={(event) =>
                setAnchorEl(anchorEl ? null : event.currentTarget)
              }
              style={{
                height: 18,
                width: 18,
                color: "#848b9e",
                cursor: "pointer",
              }}
            />
            <CustomPopper
              disablePortal={true}
              open={open}
              anchorEl={anchorEl}
              position="top"
              handleClose={() => setAnchorEl(null)}
              sx={{ minWidth: "360px" }}
            >
              {popupContent}
            </CustomPopper>
          </Box>
        )}
      </Box>
      <Box
        className={classNames(
          "mask-input-wrapper",
          showTypeBox && "type-box-available"
        )}
      >
        {showTypeBox && (
          <Box
            className="mask-type-box"
            sx={{ width: `${typeBoxWidth} !important` }}
          >
            {typeValue}
          </Box>
        )}
        <MaskedInput
          ref={ref}
          mask={currencyMask}
          className={classes}
          placeholder={placeholder ? placeholder : "0.0"}
          guide={false}
          value={value}
          onChange={onChange}
          autoComplete="off"
          {...props}
        />
        {endInputIconElement}
      </Box>
    </>
  );
});

export default InputMask;
