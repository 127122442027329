import React from "react";
import CustomSelect from "../Select/Select";
import MenuItem from "@mui/material/MenuItem";

import { Box } from "@mui/material";
import PropTypes from "prop-types";
import AlertMessage from "../AlertMessage/AlertMessage";
import { mobileNoPlaceholder, mobileNumberValidate } from "../messages";
import { getCountryObj } from "../constants";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input/input";
import "./phone-input.scss";

function PhoneNumberInput({ showLabel, ...props }) {
  return (
    <Box>
      {showLabel && (
        <label className="input-with-dropdown-label">{props.label}</label>
      )}
      <Box className="dropdown-input-wrapper">
        <CustomSelect
          onOpen={() => props.onOpen && props.onOpen()}
          onClose={() => props.onClose && props.onClose()}
          value={props.selectValue}
          showLabel={false}
          MenuProps={props.MenuProps}
          renderValue={(value) => {
            if (value) {
              let mobileInfo = getCountryObj(
                "calling_code",
                typeof value === "object" ? value.calling_code : value,
                props.options
              );
              return mobileInfo && mobileInfo.calling_code
                ? "+" + mobileInfo.calling_code
                : "";
            } else {
              return "";
            }
          }}
          onChange={(event) => {
            props.onChangeSelect(event.target.value);
            setTimeout(() => {
              props.onBlur();
            }, 100);
          }}
        >
          {props.options.map((option) => (
            <MenuItem
              style={{ display: "flex", justifyContent: "space-between" }}
              key={option.id}
              value={option.calling_code}
            >
              <Box> {option.short_name} </Box>
              <Box> {"+" + option.calling_code} </Box>
            </MenuItem>
          ))}
        </CustomSelect>

        <Input
          option={props.option}
          tabIndex={0}
          className={`phone-number-input ${
            props.isErrorClass && "phone-number-input-error"
          }`}
          country={props.countryIsoCode}
          international
          placeholder={mobileNoPlaceholder}
          value={props.inputValue}
          onBlur={() => {
            props.onBlur();
          }}
          onChange={(value) => {
            props.onChange(value || null);
          }}
        />
      </Box>
      {props.error && (
        <AlertMessage
          className="margin-top14"
          severity="error"
          message={mobileNumberValidate(props.label)}
        />
      )}
    </Box>
  );
}

const propTypes = {
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onChangeSelect: PropTypes.func,
};

const defaultProps = {
  label: "Mobile Number",
  showLabel: true,
  options: [
    { label: "option1", value: "option1" },
    { label: "option2", value: "option2" },
    { label: "option3", value: "option3" },
  ],
};
PhoneNumberInput.propTypes = propTypes;
PhoneNumberInput.defaultProps = defaultProps;

export default PhoneNumberInput;
