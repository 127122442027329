import React from "react";
import { Box } from "@mui/material";
import AccountNameBox from "./AccountNameBox";
import PageLoader from "./PageLoader";
import Translation from "./Translation";
import Text from "./Text/Text";

const CommonInBothPreview = (props) => {
  const {
    component,
    liveMode,
    accountName,
    brandingPageUI,
    currentAccount,
    paymentInfo,
    fontStyle,
    loader,
    textStyle,
    totalAmount,
    previewColor,
    type,
    visibleInPaymentPage,
    expireTimeHandler,
  } = props;

  const accountNameProps = {
    textStyle,
    accountName,
    brandingPageUI,
    currentAccount,
    isMobile: props.isMobile,
    paymentInfo,
    fontStyle,
    previewColor,
    expireTimeHandler,
    confirmPayment: props.confirmPayment,
    type,
    totalAmount,
    visibleInPaymentPage,
  };

  const components = () => {
    switch (component) {
      case "testLiveMode":
        return (
          !liveMode && (
            <Box className="live-test-mode-header">
              <Text
                size={10}
                className="default-text"
                sx={{
                  textTransform: "uppercase",
                  fontWeight: 500,
                }}
                font="semibold"
                variant="subtitle1"
              >
                <Translation value="testMode" />
              </Text>
            </Box>
          )
        );

      case "accountHeader":
        return <AccountNameBox {...accountNameProps} {...props} />;

      case "pageLoader":
        return (
          <PageLoader
            open={loader}
            alt="loading..."
            customClass="page-loader preview-loader"
            sx={{ position: "absolute" }}
          />
        );
    }
  };
  return components();
};

export default CommonInBothPreview;
