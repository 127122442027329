import React, { useContext, useState } from "react";
import { Box } from "@mui/material";
import classNames from "classnames";
import CheckoutViewCalculationDrawer from "../CheckoutViewCalculationDrawer";
import Text from "../Text/Text";
import PageTIDPreview from "../PageTIDPreview";
import { viewCalculation } from "../constants";
import { payableAmount } from "../../messages";
import { WebPreviewContext } from "../../contexts/contexts";
import HeaderAmountSection from "../HeaderAmountSection";

const HeaderForWeb = (props) => {
  const {
    isPartialPaymentAvailable,
    fontStyle,
    confirmPayment,
    paymentData,
    customClass,
    visibleInPaymentPage,
    displayAmountInCurrency,
    selectedAssetCurrency,
    paymentDetailsObj,
  } = props;

  const [showViewCalculationDrawer, setShowViewCalculationDrawer] =
    useState(false);

  const classes = classNames("latest-header-section-wrapper", customClass);

  const checkPartialPayment = isPartialPaymentAvailable && !confirmPayment;

  // Get context value using consumer

  const WebPreviewProps = useContext(WebPreviewContext);

  return (
    <Box className={classes}>
      {checkPartialPayment && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="12px"
        >
          <Text
            size={16}
            font="medium"
            variant="subtitle1"
            sx={{ ...fontStyle }}
          >
            {payableAmount}
          </Text>
          {viewCalculation(fontStyle, setShowViewCalculationDrawer)}
        </Box>
      )}

      {WebPreviewProps?.TIDVisible ? (
        <PageTIDPreview
          {...props}
          visibleInPaymentPage={visibleInPaymentPage}
          displayAmountInCurrency={displayAmountInCurrency}
        />
      ) : (
        <HeaderAmountSection
          {...props}
          resolution="web"
          visibleInPaymentPage={visibleInPaymentPage}
        />
      )}

      {checkPartialPayment && (
        <CheckoutViewCalculationDrawer
          fontFamily={fontStyle}
          openViewCalculationDrawer={showViewCalculationDrawer}
          closeViewCalculationDrawer={() => setShowViewCalculationDrawer(false)}
          paymentData={paymentData}
          paymentDetailsObj={paymentDetailsObj}
          selectedAssetCurrency={selectedAssetCurrency}
        />
      )}
    </Box>
  );
};

export default HeaderForWeb;
