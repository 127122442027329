import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { dueDate, invoice, memoTitle, to, from } from "../messages";
import Text from "./Text/Text";
import TruncatedTextTooltip from "./TruncatedTextTooltip";
import { addDaysToCurrentDate, dateFromTimestamp } from "./constants";

const EmailInfo = ({
  accountName,
  paymentInfo,
  fontFamily,
  color,
  isGreyText,
  isGetTimezoneFromSystem = false,
  isFromEmailPreview = false,
  isDisplayStaticData = false,
  timezone: timezoneToBeUsed = null,
}) => {
  const [payment, setPayment] = useState(null);
  const [timezone, setTimezone] = useState(null);

  useEffect(() => {
    setPayment(paymentInfo?.payment);
    if (isGetTimezoneFromSystem) {
      setTimezone(timezoneToBeUsed);
    } else {
      paymentInfo?.payment?.timezone &&
        setTimezone(paymentInfo?.payment?.timezone);
    }
  }, [paymentInfo]);

  const renderEmailInfoRow = (label, value) => {
    return (
      <TableRow className="invoice-info-tbl-row">
        <TableCell sx={{ width: 105 }}>
          <Text
            size={16}
            font="regular"
            variant="h6"
            className={isGreyText ? "grey-text" : ""}
            fontFamily={fontFamily}
            color={color}
          >
            {label}
          </Text>
        </TableCell>
        <TableCell sx={{ width: 250 }}>
          <TruncatedTextTooltip
            textValue={value}
            cellWidth="140px"
            enterTouchDelay={0}
            textProps={{
              size: 16,
              variant: "h6",
              className: isGreyText ? "default-text" : "",
              fontWeight: 500,
              fontFamily: fontFamily,
              color: color,
            }}
          />
        </TableCell>
      </TableRow>
    );
  };
  const getDueDate = isDisplayStaticData
    ? addDaysToCurrentDate(payment?.payment_terms || 0)
    : payment?.invoice_due_date;
  return (
    <TableContainer className="invoice-page-accordion">
      <Table aria-label="a dense table">
        <TableBody>
          {renderEmailInfoRow(to, payment?.customer?.name || "-")}
          {isFromEmailPreview && renderEmailInfoRow(from, accountName)}
          {renderEmailInfoRow(
            invoice,
            isDisplayStaticData
              ? `${payment?.invoice_prefix || "INV"}-DRAFT`
              : payment?.invoice_number
          )}
          {!isFromEmailPreview &&
            renderEmailInfoRow(
              dueDate,
              payment
                ? dateFromTimestamp(getDueDate, "MM/DD/YYYY", timezone)
                : "-"
            )}

          {payment?.memo && renderEmailInfoRow(memoTitle, payment.memo)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EmailInfo;
