import React, { createRef, useEffect, useState, useContext } from "react";
import ContactInfo from "./ContactInfo";
import { PaymentPageContext } from "../../contexts/contexts";
import CustomFieldsPreview from "./CustomFieldsPreview";
import Button from "../Button/Button";
import { submitAndGetQR, updateAndGetQR } from "../messages";
import { callAPIInterfaceCheckout } from "@speed/checkout/src/components/constants";
import { isEmpty } from "lodash";
import { formattedCustomField } from "../constants";
import history from "../history";

const RightSideDetailsSection = (props) => {
  const {
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    showEditedContactInfo,
    setShowEditedContactInfo,
    showCustomerCollections,
    paymentInfo,
    isMobile,
    isContactInfoFormVisible,
    isCustomFieldVisible,
    isWebPreview,
    updatePaymentInfoFn,
    visibleInPaymentPage,
    checkoutSessionId,
    setSubmitClicked,
    contactFormExist,
    customFieldExist,
  } = props;

  const [submitBtnLoader, setSubmitBtnLoader] = useState(false);
  const [contactDisable, setContactDisable] = useState(false);
  const [customFieldDisable, setCustomFieldDisable] = useState(false);
  const [customFieldsData, setCustomFieldsData] = useState({});

  const contactRef = createRef();

  const paymentPageData = useContext(PaymentPageContext);

  const isCheckedDetails =
    checkedBillingAddress ||
    checkedEmailAddress ||
    checkedPhoneAddress ||
    checkedShippingAddress;

  useEffect(() => {
    // Set disable to false when contact info is not available
    !isContactInfoFormVisible && setContactDisable(false);
  }, [isContactInfoFormVisible]);

  useEffect(() => {
    // Set disable to false when custom field is not available
    !isCustomFieldVisible && setCustomFieldDisable(false);
  }, [isCustomFieldVisible]);

  useEffect(() => {
    // Reset custom field data if custom field is unchecked
    !paymentInfo?.custom_fields && setCustomFieldsData([]);
  }, [paymentInfo?.custom_fields]);

  const handleSubmit = async () => {
    const contactInfo = (await contactRef?.current?.getContactInfo?.()) || {};

    let isValid = [],
      payload = {};

    if (!isEmpty(contactInfo?.payloadData)) {
      payload = {
        ...payload,
        ...contactInfo?.payloadData,
      };
      isValid.push(contactInfo?.isValidToCallAPI);
    }

    if (!isEmpty(customFieldsData?.payloadData)) {
      payload = {
        ...payload,
        custom_fields: customFieldsData?.payloadData,
      };
      isValid.push(customFieldsData?.isValidToCallAPI);
    }

    const checkIsValid = isValid.every((val) => val === true);

    if (visibleInPaymentPage && checkIsValid) {
      setSubmitBtnLoader(true);
      callAPIInterfaceCheckout(
        "POST",
        `/payment-page/${checkoutSessionId}`,
        payload
      )
        .then((data) => {
          setSubmitClicked(true);

          const checkoutData = paymentPageData?.checkoutData;
          const parsedData = JSON.parse(data);
          const reformResult = {
            ...checkoutData,
            paymentInfo: {
              ...checkoutData.paymentInfo,
              customer_collections: parsedData.customer_collections,
              custom_fields: formattedCustomField(parsedData.custom_fields),
              payment: {
                ...checkoutData?.paymentInfo?.payment,
                payment: parsedData.payment,
                expires_at: parsedData?.expires_at,
                exchange_rate: parsedData?.exchange_rate,
              },
            },
            isMultiPaymentMethods: parsedData.payment?.payment_method_options,
          };
          paymentPageData.setCheckoutData(reformResult);
          setShowEditedContactInfo(false);
          setSubmitBtnLoader(false);
        })
        .catch((err) => {
          const errorArr = err?.response?.data?.errors?.[0];
          if (errorArr?.type === "account_restriction") {
            history.push("/pay/error/under-maintenance", {
              message: errorArr?.message,
            });
          }
          setSubmitClicked(true);
          setSubmitBtnLoader(false);
        });
    } else {
      setSubmitClicked(true);
      setShowEditedContactInfo(false);
      if (!visibleInPaymentPage) {
        const updatedPaymentInfo = paymentInfo;
        if (!isEmpty(contactInfo))
          updatedPaymentInfo["customer_collections"] = contactInfo?.payloadData;
        if (!isEmpty(customFieldsData))
          updatedPaymentInfo["custom_fields"] = customFieldsData?.payloadData;
        updatePaymentInfoFn(updatedPaymentInfo);
      }
    }
  };

  return (
    <>
      {(isContactInfoFormVisible || contactFormExist) && (
        <ContactInfo
          checkedBillingAddress={checkedBillingAddress}
          checkedEmailAddress={checkedEmailAddress}
          checkedPhoneAddress={checkedPhoneAddress}
          checkedShippingAddress={checkedShippingAddress}
          customerCollections={showCustomerCollections}
          isWebPreview={isWebPreview}
          isMobile={isMobile}
          ref={contactRef}
          setContactDisable={setContactDisable}
          CFIsValidForm={customFieldsData?.isValidToCallAPI}
        />
      )}
      {(isCustomFieldVisible || customFieldExist) && (
        <CustomFieldsPreview
          {...props}
          isCheckedDetails={isCheckedDetails}
          setCustomFieldDisable={setCustomFieldDisable}
          contactDisable={contactDisable}
          setCustomFieldsData={setCustomFieldsData}
        />
      )}
      {(isCheckedDetails || isCustomFieldVisible) && (
        <Button
          sx={{
            marginTop: !isCheckedDetails && isCustomFieldVisible ? 0 : "30px",
            marginBottom: "30px",
            "&.MuiLoadingButton-loading": {
              backgroundColor: "#d8dce1 !important",
            },
          }}
          label={showEditedContactInfo ? updateAndGetQR : submitAndGetQR}
          variant="contained"
          color="primary"
          className="payout-link-btn"
          onClick={handleSubmit}
          iconPosition="right"
          icon="rightArrowIcon"
          disabled={contactDisable || customFieldDisable}
          loading={submitBtnLoader}
          type="submit"
        />
      )}
    </>
  );
};

export default RightSideDetailsSection;
