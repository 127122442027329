import React, { useEffect, useState } from "react";
import { Drawer, Box } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Translation from "./Translation";
import CloseIcon from "@mui/icons-material/Close";
import CustomDivider from "./Divider/Divider";
import Text from "./Text/Text";
import CustomAccordion from "./Accordion/Accordion";
import { CustomLink } from "./Link/Link";
import { isMobile } from "react-device-detect";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { moreQuestionLabel, reachOutText } from "../messages";

const HowToPayDrawer = ({
  openHowToPayDrawer,
  closeHowToPayDrawer,
  fontFamily,
}) => {
  const titleFontStyle = { fontFamily, fontWeight: 600 };
  const [expanded, setExpanded] = useState(1);
  const [content, setContent] = useState([]);

  const getDrawerContent = () => {
    const host = process.env.REACT_APP_STRAPI_API_ENDPOINT;
    const token = process.env.REACT_APP_STRAPI_API_TOKEN;
    const jwtOptions = {
      method: "GET",
      url: `${host}/api/payment-page-helps`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(jwtOptions)
      .then((res) => {
        const response = res.data?.data;
        const moreQuestion = {
          id: response.length + 1,
          attributes: {
            question: (
              <Text
                variant="caption"
                className="default-text"
                style={{ fontFamily, display: "grid" }}
              >
                <Box component="span" className="more-question-text">
                  {moreQuestionLabel}
                </Box>
                <Box component="span" className="more-question-subtext">
                  {reachOutText[0]}
                  <CustomLink
                    href="https://apidocs.tryspeed.com/discuss"
                    target="_blank"
                    style={{ fontSize: "14px" }}
                  >
                    {reachOutText[1]}
                  </CustomLink>
                  {reachOutText[2]}
                </Box>
              </Text>
            ),
          },
        };
        setContent([...res.data?.data, moreQuestion]);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getDrawerContent();
  }, []);

  const handleCloseDrawer = () => {
    setExpanded(1);
    closeHowToPayDrawer(false);
  };

  const DrawerHeader = () => {
    return (
      <Box className="drawer-header">
        <Text
          size={18}
          variant="h5"
          className="default-text"
          style={titleFontStyle}
        >
          {!isMobile && <HelpOutlineIcon className="drawer-help-icon" />}
          <Translation value="help" />
        </Text>
        <Box
          height={20}
          width={20}
          onClick={handleCloseDrawer}
          component="span"
          className="pointer-cursor"
        >
          <CloseIcon sx={{ height: 20, width: 20 }} htmlColor="#848b9e" />
        </Box>
      </Box>
    );
  };

  const CommonTextElement = ({
    faqAnswer,
    customClass,
    showDivider = true,
    ...props
  }) => {
    return (
      <>
        {showDivider && <CustomDivider />}
        <Text
          size={14}
          variant="h5"
          className={customClass}
          font="regular"
          style={{ fontFamily }}
          {...props}
        >
          <ReactMarkdown
            className="payment-page-markdown"
            children={faqAnswer}
          />
        </Text>
      </>
    );
  };

  const handleChange = (title) => (_e, newExpanded) => {
    setExpanded(newExpanded && title.id !== content.length ? title.id : false);
  };

  const drawerBodyContent = () => (
    <>
      {content.map((instruction) => {
        const { attributes } = instruction;

        let accordianIcon;
        if (instruction.id !== content.length) {
          accordianIcon =
            expanded === instruction.id ? "removeIcon" : "addIcon";
        }
        return (
          <CustomAccordion
            textStyle={{
              fontFamily: `${fontFamily} !important`,
              fontWeight: 600,
            }}
            className="checkout-help-faq"
            isTitleShown={true}
            key={instruction.id}
            expandIcon={accordianIcon}
            onChange={(title) => handleChange(title)}
            expanded={expanded === instruction.id}
            accordianPanel={instruction}
            title={attributes.question}
            customComponent={
              <CommonTextElement
                customClass="default-text faq-answer"
                faqAnswer={attributes.answer}
              />
            }
          />
        );
      })}
    </>
  );

  return (
    <Drawer
      id="checkout-help-drawer"
      className="checkout-drawer"
      anchor="right"
      open={openHowToPayDrawer}
      onClose={handleCloseDrawer}
    >
      <Box className="drawer-wrapper" role="presentation">
        <DrawerHeader />
        <CustomDivider />
        <Box className="help-body-content">{drawerBodyContent()}</Box>
      </Box>
    </Drawer>
  );
};

export default HowToPayDrawer;
