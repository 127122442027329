import React, { useEffect } from "react";
import { retryImage } from "./images";
import Text from "@speed/common/src/components/Text/Text";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@speed/common/src/components/Button/Button";
import Translation from "./Translation";
import { CustomLink } from "./Link/Link";
import WarningIcon from "@mui/icons-material/Warning";

const ExpireModal = ({
  open,
  handleClose,
  cancel_url,
  handleCancel,
  isFromCancel = false,
}) => {
  useEffect(() => {
    let element = document.getElementById("root")?.style;
    if (element) {
      open ? (element.filter = "blur(6px)") : (element.filter = "none");
    }
    return () => {
      if (element) element.filter = "none";
    };
  }, [open]);

  return (
    <Dialog
      id="expire-modal"
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"sx"}
      className="expire-modal"
      disableEscapeKeyDown
    >
      <DialogContent>
        {isFromCancel ? (
          <WarningIcon
            sx={{ color: "#E74C3C", height: 44, width: 50 }}
            className="expire-icon"
          />
        ) : (
          <img
            src={retryImage}
            className="expire-icon"
            alt="check-email"
            height={44}
            width={50}
          />
        )}
        <Text
          className="expire-header"
          size={18}
          font="semibold"
          variant="body1"
        >
          <Translation
            value={isFromCancel ? "confirmLeaving" : "tryAgainHeader"}
          />
        </Text>
        <Text
          className="expire-sub-header"
          size={14}
          font="medium"
          variant="subtitle1"
        >
          <Translation
            value={
              isFromCancel ? "confirmLeavingSubHeader" : "tryAgainSubHeader"
            }
          />
        </Text>
      </DialogContent>
      <DialogActions sx={{ display: "flex", flexDirection: "column" }}>
        <Button
          loading={false}
          disabled={false}
          fullWidth
          type="submit"
          onClick={() => handleClose()}
          label={
            <Translation value={isFromCancel ? "yesGoBack" : "tryAgain"} />
          }
        />
        {cancel_url && (
          <CustomLink
            size="small"
            bold
            className="margin-top15"
            onClick={() => handleCancel()}
          >
            <Translation value={isFromCancel ? "noStayHere" : "cancel"} />
          </CustomLink>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExpireModal;
