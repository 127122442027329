import React from "react";
import { Box } from "@mui/material";
import Text from "../Text/Text";
import Translation from "../Translation";
import EmailInfo from "../EmailInfo";

const InvoiceInformation = ({ fontStyle, paymentInfo, webPreviewProps }) => {
  return (
    <Box className="email-info-section">
      <Text
        fontFamily={fontStyle?.fontFamily}
        color={fontStyle?.color}
        size={16}
        font="regular"
        variant="h6"
        sx={{ marginBottom: "24px" }}
      >
        <Translation value="invoiceInfo" />
      </Text>
      <EmailInfo
        paymentInfo={paymentInfo}
        fontFamily={fontStyle?.fontFamily}
        color={fontStyle?.color}
        isGetTimezoneFromSystem={webPreviewProps?.isGetTimezoneFromSystem}
        isDisplayStaticData={webPreviewProps?.isDisplayStaticData}
        timezone={webPreviewProps?.timezone}
      />
    </Box>
  );
};

export default InvoiceInformation;
