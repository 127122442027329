import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Box } from "@mui/material";
import classNames from "classnames";
import Text from "../Text/Text";
import { useFormik, setNestedObjectValues } from "formik";
import { Input } from "../Input/Input";
import * as yup from "yup";
import {
  billingAddress,
  contactInfo,
  email,
  invalidEmail,
  shippingAddress,
} from "../messages";
import { CustomCheckbox } from "../Checkbox/Checkbox";
import { Countries } from "../country";
import {
  getCountryObj,
  isAlphaNumericCharAllowed,
  validatePhoneNumber,
  validatePostalCode,
} from "../constants";
import { shippingAddressIsAsBillingAddress } from "../../messages";
import PhoneNumberInput from "../PhoneNumberInput/PhoneNumberInput";
import { useParams } from "react-router-dom";
import BillingAndShippingAddress from "./BillingAndShippingAddress";
import AlertMessage from "../AlertMessage/AlertMessage";
import Translation from "../Translation";

const ContactInfo = forwardRef(
  (
    {
      checkedBillingAddress,
      checkedEmailAddress,
      checkedPhoneAddress,
      checkedShippingAddress,
      isWebPreview,
      customerCollections,
      isMobile,
      setContactDisable,
      CFIsValidForm,
    },
    ref
  ) => {
    const urlParams = useParams();
    const [countryISOCode, setCountryISOCode] = useState();
    const [billingCountryISOCode, setBillingCountryISOCode] = useState();
    const [shippingCountryISOCode, setShippingCountryISOCode] = useState();

    const validationSchema = yup.object({
      email:
        checkedEmailAddress &&
        yup.string().email(invalidEmail).required("Invalid"),
      phone:
        checkedPhoneAddress &&
        yup
          .mixed()
          .test({
            name: "phone",
            test: (phoneNumber) => validatePhoneNumber(phoneNumber),
          })
          .required("Invalid"),
      billing_name:
        checkedBillingAddress &&
        yup.string().matches(isAlphaNumericCharAllowed).required(""),
      billing_address:
        checkedBillingAddress &&
        yup.string().matches(isAlphaNumericCharAllowed).required(""),
      billing_country: checkedBillingAddress && yup.mixed().required(""),
      billing_state:
        checkedBillingAddress &&
        yup.string().matches(isAlphaNumericCharAllowed).required(""),
      billing_city:
        checkedBillingAddress &&
        yup.string().matches(isAlphaNumericCharAllowed).required(""),
      billing_zipcode:
        checkedBillingAddress &&
        yup
          .mixed()
          .test({
            name: "billing_zipcode",
            test: (billing_zipcode) =>
              validatePostalCode(billing_zipcode, billingCountryISOCode),
          })
          .required(),
      is_same_as_billing: yup.boolean(),
      shipping_name: yup.string().when("is_same_as_billing", {
        is: false,
        then: yup.string().matches(isAlphaNumericCharAllowed).required(),
      }),
      shipping_address: yup.string().when("is_same_as_billing", {
        is: false,
        then: yup.string().matches(isAlphaNumericCharAllowed).required(),
      }),
      shipping_country: yup.mixed().when("is_same_as_billing", {
        is: false,
        then: yup.mixed().required(),
      }),
      shipping_state: yup.string().when("is_same_as_billing", {
        is: false,
        then: yup.string().matches(isAlphaNumericCharAllowed).required(),
      }),
      shipping_city: yup.string().when("is_same_as_billing", {
        is: false,
        then: yup.string().matches(isAlphaNumericCharAllowed).required(),
      }),

      shipping_zipcode: yup.mixed().when("is_same_as_billing", {
        is: false,
        then: yup
          .mixed()
          .test({
            name: "shipping_zipcode",
            test: (shipping_zipcode) =>
              validatePostalCode(shipping_zipcode, shippingCountryISOCode),
          })
          .required(),
      }),
    });

    const getExtractedCallingCode = (callingCode) => {
      const splitedArr = callingCode.split(" ");
      return splitedArr[0].slice(1);
    };

    const formik = useFormik({
      initialValues: {
        email: customerCollections?.email || "",
        phone: customerCollections?.phone || "",
        billing_name: customerCollections?.billing_address?.name || "",
        billing_address: customerCollections?.billing_address?.line1 || "",
        billing_country: customerCollections?.billing_address?.country || null,
        billing_state: customerCollections?.billing_address?.state || "",
        billing_city: customerCollections?.billing_address?.city || "",
        billing_zipcode:
          customerCollections?.billing_address?.postal_code || null,
        shipping_name: customerCollections?.shipping_address?.name || "",
        shipping_address: customerCollections?.shipping_address?.line1 || "",
        shipping_country:
          customerCollections?.shipping_address?.country || null,
        shipping_state: customerCollections?.shipping_address?.state || "",
        shipping_city: customerCollections?.shipping_address?.city || "",
        shipping_zipcode:
          customerCollections?.shipping_address?.postal_code || null,
        is_same_as_billing:
          customerCollections?.billing_address &&
          customerCollections?.shipping_address
            ? JSON.stringify(customerCollections?.billing_address) ===
              JSON.stringify(customerCollections?.shipping_address)
            : true,
        calling_code: customerCollections?.phone
          ? getExtractedCallingCode(customerCollections?.phone)
          : "1",
        is_checked_email: checkedEmailAddress,
        is_checked_phone: checkedPhoneAddress,
        is_checked_billing: checkedBillingAddress,
        is_checked_shipping: checkedShippingAddress,
      },
      validationSchema: validationSchema,
    });

    const { values, setFieldValue, touched, errors, setTouched, validateForm } =
      formik;

    useEffect(() => {
      const country = getCountryObj(
        "short_name",
        typeof values?.billing_country === "object"
          ? values?.billing_country?.short_name
          : values?.billing_country
      );
      setBillingCountryISOCode(country?.iso2);
    }, [values?.billing_country]);

    useEffect(() => {
      const country = getCountryObj(
        "short_name",
        typeof values?.shipping_country === "object"
          ? values?.shipping_country?.short_name
          : values?.shipping_country
      );
      setShippingCountryISOCode(country?.iso2);
    }, [values?.shipping_country]);

    useEffect(async () => {
      const errors = await validateForm(values);
      if (Object.keys(errors).length === 0) setContactDisable(false);
      else setContactDisable(true);
    }, [values]);

    useImperativeHandle(ref, () => ({
      getContactInfo: async () => {
        const errors = await validateForm(values);
        if (Object.keys(errors).length === 0) {
          const contactInfoData = {};
          if (checkedEmailAddress) {
            contactInfoData.email = values.email;
          }
          if (checkedPhoneAddress) {
            contactInfoData.phone = values.phone;
          }
          if (checkedBillingAddress) {
            contactInfoData.billing_address = {
              name: values.billing_name,
              line1: values.billing_address,
              city: values.billing_city,
              state: values.billing_state,
              country: values.billing_country,
              postal_code: values.billing_zipcode,
            };
          }
          if (checkedShippingAddress) {
            const isSameAsBilling =
              checkedBillingAddress && values.is_same_as_billing;
            contactInfoData.shipping_address = {
              name: isSameAsBilling
                ? values.billing_name
                : values.shipping_name,
              line1: isSameAsBilling
                ? values.billing_address
                : values.shipping_address,
              city: isSameAsBilling
                ? values.billing_city
                : values.shipping_city,
              state: isSameAsBilling
                ? values.billing_state
                : values.shipping_state,
              country: isSameAsBilling
                ? values.billing_country
                : values.shipping_country,
              postal_code: isSameAsBilling
                ? values.billing_zipcode
                : values.shipping_zipcode,
            };
          }
          return {
            payloadData: contactInfoData,
            isValidToCallAPI: formik.initialValues !== values || CFIsValidForm,
          };
        } else {
          setTouched(setNestedObjectValues(errors, true));
        }
      },
    }));

    useEffect(() => {
      // Set country code according to calling_code
      let countryObj = getCountryObj("calling_code", values.calling_code);
      if (countryObj) setCountryISOCode(countryObj.iso2);
    }, [values.calling_code]);

    const getObjectForResetting = (typeOfObj) => {
      if (!typeOfObj) return {};
      let resetObj = {
        [`${typeOfObj}_name`]: "",
        [`${typeOfObj}_address`]: "",
        [`${typeOfObj}_country`]: null,
        [`${typeOfObj}_state`]: "",
        [`${typeOfObj}_city`]: "",
        [`${typeOfObj}_zipcode`]: "",
      };
      return resetObj;
    };

    const resetObjectFormValues = (resetObjectFormValues) => {
      if (!resetObjectFormValues) return;
      const fieldsToResetArray = Object.entries(resetObjectFormValues);
      if (fieldsToResetArray?.length) {
        fieldsToResetArray.map(([field, value]) => setFieldValue(field, value));
      }
    };

    const previewDropdownPosition = isWebPreview
      ? "contact-info-select-calling-code-preview"
      : "contact-info-mobile-select-calling-code-preview";

    const checkoutPageDropdownPosition = isMobile
      ? "select-contact-info-calling-code-mobile"
      : "select-contact-info-calling-code";

    const handleDynamicWidthForMenu = async () => {
      document.body.style.overflow = "hidden";

      const formGridElement = await document.querySelector(
        ".contact-info-phone-number-input.mobile-preview"
      );

      const callingCodeMenuElement = await document.querySelector(
        "#select-contact-info-calling-code-mobile .MuiPaper-root"
      );

      if (isMobile && formGridElement && callingCodeMenuElement) {
        const dynamicWidth = formGridElement.offsetWidth + "px";
        callingCodeMenuElement.setAttribute(
          "style",
          `width:${dynamicWidth} !important`
        );
      }
    };

    const emailInputClasses = classNames(
      touched.email && errors.hasOwnProperty("email") && "border-error",
      !checkedPhoneAddress && "custom-input-zip-info"
    );

    const phoneNumberInputClasses = classNames(
      touched.phone && errors.hasOwnProperty("phone") && "border-error",
      "contact-info-phone-number-input",
      !isWebPreview && "mobile-preview",
      touched.email && errors.hasOwnProperty("email") && "border-error-unset"
    );

    return (
      <Box className="payment-page-qr-section payment-page-contact-info">
        {(checkedEmailAddress || checkedPhoneAddress) && (
          <Text
            size={16}
            variant="subtitle1"
            className="default-text"
            sx={{ marginBottom: "14px", fontFamily: "Inter-Medium !important" }}
          >
            {contactInfo}
          </Text>
        )}
        {checkedEmailAddress && (
          <Input
            className={emailInputClasses}
            style={{
              borderRadius: checkedPhoneAddress ? "4px 4px 0px 0px" : "4px",
            }}
            type="email"
            inputProps={{ maxLength: 250 }}
            showLabel={false}
            name="email"
            value={values.email}
            fullWidth
            placeholder={email}
            onBlur={() => setTouched({ ...touched, email: true })}
            onChange={(e) => {
              setTouched({ ...touched, email: false });
              setFieldValue("email", e.target.value);
            }}
            error={touched.email && Boolean(errors.email)}
          />
        )}
        {checkedPhoneAddress && (
          <Box
            className={phoneNumberInputClasses}
            style={{
              borderRadius: checkedEmailAddress ? "0px 0px 4px 4px" : "4px",
            }}
          >
            <PhoneNumberInput
              onOpen={() => {
                if (handleDynamicWidthForMenu) {
                  handleDynamicWidthForMenu();
                }
              }}
              onClose={() => {
                document.body.style.overflow = "auto";
              }}
              name="phone_number"
              showLabel={false}
              options={Countries}
              selectValue={values.calling_code}
              countryIsoCode={countryISOCode}
              MenuProps={{
                id: !urlParams?.id
                  ? previewDropdownPosition
                  : checkoutPageDropdownPosition,
                disableScrollLock: true,
              }}
              inputValue={values.phone}
              onChangeSelect={(value) => {
                setFieldValue(
                  "calling_code",
                  typeof value === "object" ? value.calling_code : value
                );
              }}
              onBlur={() => setTouched({ ...touched, phone: true })}
              onChange={(value) => {
                const newVal =
                  value?.replace(
                    values.calling_code,
                    `${values.calling_code} `
                  ) || "";
                setTouched({ ...touched, phone: false });
                setFieldValue("phone", newVal);
              }}
            />
          </Box>
        )}
        {touched.email && Boolean(errors.email) && (
          <AlertMessage
            message={<Translation value="emailInvalid" />}
            className="margin-top15"
            severity="error"
          />
        )}
        {touched.phone && Boolean(errors.phone) && (
          <AlertMessage
            message={<Translation value="phoneInvalid" />}
            className="margin-top15"
            severity="error"
          />
        )}
        {checkedBillingAddress && (
          <BillingAndShippingAddress
            addressType={billingAddress}
            addressName={"billing_name"}
            addressBillOrShip={"billing_address"}
            addressCountry={"billing_country"}
            addressState={"billing_state"}
            addressCity={"billing_city"}
            addressZipcode={"billing_zipcode"}
            setCountryIso={setBillingCountryISOCode}
            checkedEmailAddress={checkedEmailAddress}
            checkedPhoneAddress={checkedPhoneAddress}
            values={values}
            touched={touched}
            setTouched={setTouched}
            setFieldValue={setFieldValue}
            errors={errors}
          />
        )}
        {checkedShippingAddress && checkedBillingAddress && (
          <CustomCheckbox
            style={{
              marginTop: "14px",
              marginLeft: `${!urlParams?.id && "2px"}`,
            }}
            checked={values.is_same_as_billing}
            label={shippingAddressIsAsBillingAddress}
            className="checkbox-label-default options"
            onChange={() => {
              setFieldValue("is_same_as_billing", !values.is_same_as_billing);
              values.is_same_as_billing &&
                resetObjectFormValues(getObjectForResetting("shipping"));
            }}
          />
        )}
        {((!checkedBillingAddress && checkedShippingAddress) ||
          (checkedBillingAddress && !values.is_same_as_billing)) && (
          <Box
            mt={!checkedBillingAddress && checkedShippingAddress ? "" : "30px"}
          >
            <BillingAndShippingAddress
              addressType={shippingAddress}
              addressName={"shipping_name"}
              addressBillOrShip={"shipping_address"}
              addressCountry={"shipping_country"}
              addressState={"shipping_state"}
              addressCity={"shipping_city"}
              addressZipcode={"shipping_zipcode"}
              setCountryIso={setShippingCountryISOCode}
              checkedEmailAddress={checkedEmailAddress}
              checkedPhoneAddress={checkedPhoneAddress}
              values={values}
              touched={touched}
              setTouched={setTouched}
              setFieldValue={setFieldValue}
              errors={errors}
            />
          </Box>
        )}
      </Box>
    );
  }
);

export default ContactInfo;
