import PropTypes from "prop-types";
import classNames from "classnames";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import { accordionIcons } from "../images";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import Text from "../Text/Text";
import "./accordion.scss";
import { Box } from "@mui/material";

function CustomAccordion({
  title,
  helperText,
  icon,
  expandIcon,
  accordianPanel,
  className,
  customComponent,
  textStyle,
  handleOnClick,
  helperElement,
  isTitleShown,
  ...props
}) {
  return (
    <Accordion
      {...props}
      onChange={props.onChange(accordianPanel)}
      className={classNames("accordion-wrapper", className)}
    >
      {isTitleShown && (
        <AccordionSummary
          expandIcon={accordionIcons[expandIcon]}
          onClick={handleOnClick}
          sx={
            props.from === "invoice-payment-preview"
              ? { padding: "12px 0px !important" }
              : {}
          }
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <Text
              withIcon="start"
              variant="body1"
              font="semibold"
              size={14}
              sx={textStyle || {}}
            >
              {" "}
              {accordionIcons[icon] || icon} {title}{" "}
            </Text>
            {helperElement}
            {helperText && (
              <Text
                withIcon="start"
                variant="body1"
                font="regular"
                className="grey-text"
                size={14}
                sx={{ marginRight: "5px" }}
              >
                {helperText}
              </Text>
            )}
          </Box>
        </AccordionSummary>
      )}
      <AccordionDetails>
        <List> {customComponent} </List>
      </AccordionDetails>
    </Accordion>
  );
}

const propTypes = {
  isTitleShown: PropTypes.bool,
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  helperElement: PropTypes.any,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  expandIcon: PropTypes.oneOf([
    "expandMore",
    "editIcon",
    "addIcon",
    "removeIcon",
    "downArrowIcon",
    "rightArrowIcon",
    "filterList",
    "calenderIcon",
  ]),
  customComponent: PropTypes.any.isRequired,
};

CustomAccordion.propTypes = propTypes;

export default CustomAccordion;
