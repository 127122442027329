import React from "react";
import PropTypes from "prop-types";
import AlertMessage from "../AlertMessage/AlertMessage";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";

const SlideTransition = React.forwardRef(function slideTrans(_props, ref) {
  return <Slide {..._props} ref={ref} direction="left" unmountOnExit />;
});

const CustomNotification = ({
  open,
  className,
  severity,
  message,
  autoHideDuration,
  ...props
}) => {
  const vertical = "top",
    horizontal = "right";

  return (
    <Snackbar
      open={open}
      className={className}
      autoHideDuration={autoHideDuration}
      disableWindowBlurListener={true}
      anchorOrigin={{ vertical, horizontal }}
      TransitionComponent={SlideTransition}
      message={!severity ? message : undefined}
      action={
        <CloseIcon
          sx={{ cursor: "pointer" }}
          onClick={(event, reason) => props.onClose(event, reason)}
        />
      }
      onClose={(event, reason) => props.onClose(event, reason)}
    >
      {severity && (
        <AlertMessage
          severity={severity}
          message={message}
          onClose={(event, reason) => props.onClose(event, reason)}
        />
      )}
    </Snackbar>
  );
};

const propTypes = {
  open: PropTypes.bool.isRequired,
  severity: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

const defaultProps = {
  open: false,
};

CustomNotification.propTypes = propTypes;
CustomNotification.defaultProps = defaultProps;

export default CustomNotification;
