import CommonCheckoutActions from "./CommonCheckoutActions";
import { tickGif } from "./images";
import { useLocation } from "react-router-dom";

const AlreadyPaidLink = () => {
  const url = useLocation();

  const showMessage = () => {
    switch (url?.state?.from) {
      case "withdraw":
        return "withdrawLinkAlreadyCompleted";
      case "invoice":
        return "invoicePaid";
      case "checkout":
      default:
        return "urlAlreadyCompleted";
    }
  };

  const getMainText = () => {
    switch (url?.state?.from) {
      case "withdraw":
        return "withdrawAlreadyCompleted";
      default:
        return "alreadyCompleted";
    }
  };

  return (
    <CommonCheckoutActions
      action="paid"
      actionMainText={getMainText()}
      actionGif={tickGif}
      actionSubText={showMessage()}
    />
  );
};

export default AlreadyPaidLink;
