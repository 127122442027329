import React from "react";
import { Box } from "@mui/material";
import Text from "../Text/Text";
import { Input } from "../Input/Input";
import { address, city, country, name, state, zipcode } from "../messages";
import { CustomAutoComplete } from "../AutoComplete/AutoComplete";
import { Countries } from "../country";
import { getCountryObj } from "../constants";
import AlertMessage from "../AlertMessage/AlertMessage";
import Translation from "../Translation";

const BillingAndShippingAddress = (props) => {
  const {
    addressType,
    addressName,
    addressBillOrShip,
    addressCountry,
    addressState,
    addressCity,
    addressZipcode,
    setCountryIso,
    checkedEmailAddress,
    checkedPhoneAddress,
    values,
    touched,
    setTouched,
    setFieldValue,
    errors,
  } = props;

  const checkConditionForBorder = (field) => {
    return touched[field] && errors.hasOwnProperty(field);
  };

  return (
    <Box mt={checkedEmailAddress || checkedPhoneAddress ? "30px" : undefined}>
      <Text
        size={16}
        variant="subtitle1"
        className="default-text"
        sx={{ marginBottom: "14px", fontFamily: "Inter-Medium !important" }}
      >
        {addressType}
      </Text>
      <Input
        className={checkConditionForBorder(addressName) && "border-error"}
        style={{
          borderRadius: "4px 4px 0px 0px",
        }}
        type="text"
        inputProps={{ maxLength: 250 }}
        showLabel={false}
        name="name"
        value={values[addressName]}
        fullWidth
        placeholder={name}
        onBlur={() => setTouched({ ...touched, [addressName]: true })}
        onChange={(e) => {
          setTouched({ ...touched, [addressName]: false });
          setFieldValue(addressName, e.target.value);
        }}
        error={touched[addressName] && Boolean(errors[addressName])}
      />
      <CustomAutoComplete
        className="custom-auto-complete"
        customClass={`${
          checkConditionForBorder(addressCountry) && "border-error"
        } ${checkConditionForBorder(addressName) && "border-error-unset"}`}
        name={addressCountry}
        options={Countries}
        sx={{
          borderRadius: "0px",
        }}
        getOptionLabel={(value) => {
          let country = getCountryObj(
            "short_name",
            typeof value === "object" ? value.short_name : value
          );
          return country ? country.short_name : undefined;
        }}
        onBlur={() => setTouched({ ...touched, [addressCountry]: true })}
        onChange={async (_e, changeCountryValues) => {
          if (changeCountryValues) {
            await setCountryIso(changeCountryValues.iso2);
          } else {
            await setCountryIso();
          }
          setTouched({ ...touched, [addressCountry]: false });
          setFieldValue(
            addressCountry,
            changeCountryValues && typeof changeCountryValues === "object"
              ? changeCountryValues.short_name
              : changeCountryValues
          );
        }}
        value={values?.[addressCountry]}
        placeholder={country}
        showLabel={false}
        disableClearable={true}
        allowedAutoComplete={true}
      />
      <Input
        type="text"
        showLabel={false}
        name="address"
        className={`
        ${checkConditionForBorder(addressBillOrShip) && "border-error"}
        ${checkConditionForBorder(addressCountry) && "border-error-unset"}`}
        value={values[addressBillOrShip]}
        fullWidth
        placeholder={address}
        onBlur={() => setTouched({ ...touched, [addressBillOrShip]: true })}
        onChange={(e) => {
          setTouched({ ...touched, [addressBillOrShip]: false });
          setFieldValue(addressBillOrShip, e.target.value);
        }}
        error={touched[addressBillOrShip] && Boolean(errors[addressBillOrShip])}
        inputProps={{ maxLength: 250 }}
      />
      <Input
        type="text"
        className={`
        ${checkConditionForBorder(addressState) && "border-error"}
        ${checkConditionForBorder(addressBillOrShip) && "border-error-unset"}`}
        showLabel={false}
        name="state"
        value={values[addressState]}
        fullWidth
        placeholder={state}
        onBlur={() => setTouched({ ...touched, [addressState]: true })}
        onChange={(e) => {
          setTouched({ ...touched, [addressState]: false });
          setFieldValue(addressState, e.target.value);
        }}
        error={touched[addressState] && Boolean(errors[addressState])}
        inputProps={{ maxLength: 250 }}
      />
      <Box display="flex">
        <Box>
          <Input
            className={`custom-input-city-info 
            ${checkConditionForBorder(addressCity) && "border-error"}
            ${checkConditionForBorder(addressState) && "border-error-unset"} ${
              !checkConditionForBorder(addressCity) && "border-right-unset"
            }`}
            style={{
              borderRadius: "0px 0px 0px 4px",
            }}
            type="text"
            showLabel={false}
            name="city"
            value={values[addressCity]}
            fullWidth
            placeholder={city}
            onBlur={() => setTouched({ ...touched, [addressCity]: true })}
            onChange={(e) => {
              setTouched({ ...touched, [addressCity]: false });
              setFieldValue(addressCity, e.target.value);
            }}
            error={touched[addressCity] && Boolean(errors[addressCity])}
            inputProps={{ maxLength: 250 }}
          />
        </Box>
        <Box>
          <Input
            className={`custom-input-zip-info 
            ${checkConditionForBorder(addressZipcode) && "border-error"}
            ${checkConditionForBorder(addressState) && "border-error-unset"} ${
              checkConditionForBorder(addressCity) && "border-left-unset"
            }`}
            style={{
              borderRadius: "0px 0px 4px 0px",
            }}
            type="text"
            showLabel={false}
            name="zipcode"
            value={values[addressZipcode]}
            fullWidth
            placeholder={zipcode}
            onBlur={() => setTouched({ ...touched, [addressZipcode]: true })}
            onChange={(e) => {
              setTouched({ ...touched, [addressZipcode]: false });
              setFieldValue(addressZipcode, e.target.value);
            }}
            error={touched[addressZipcode] && Boolean(errors[addressZipcode])}
            inputProps={{ maxLength: 250 }}
          />
          {touched[addressZipcode] && Boolean(errors[addressZipcode]) && (
            <AlertMessage
              message={<Translation value="zipcodeInvalid" />}
              className="margin-top15"
              severity="error"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BillingAndShippingAddress;
