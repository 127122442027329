import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import {
  checkBoxOff,
  checkBoxOn,
  checkBoxOnDisabled,
  checkBoxOffDisabled,
} from "../images";
import classNames from "classnames";
import "./checkbox.scss";

export const CustomCheckbox = ({
  label,
  checked,
  disabled,
  className,
  indeterminate = false,
  ...props
}) => {
  let customCheckboxIcon;
  if (indeterminate) {
    customCheckboxIcon = <IndeterminateCheckBoxOutlinedIcon />;
  } else {
    customCheckboxIcon = disabled ? checkBoxOffDisabled : checkBoxOff;
  }

  return (
    <FormControlLabel
      className={classNames(
        "checkbox-wrapper",
        className,
        indeterminate && "indeterminate-checkbox"
      )}
      disabled={disabled}
      control={
        <Checkbox
          icon={customCheckboxIcon}
          checkedIcon={disabled ? checkBoxOnDisabled : checkBoxOn}
          checked={checked}
          disableRipple
        />
      }
      label={label}
      {...props}
    />
  );
};

export const propTypes = {
  label: PropTypes.any.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  checked: false,
  disabled: false,
};

CustomCheckbox.propTypes = propTypes;
CustomCheckbox.defaultProps = defaultProps;
