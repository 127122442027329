import React from 'react';

const PaymentLinkCheckout = () => {
    return (
        <div>
            PaymentLinkCheckout
        </div>
    );
};

export default PaymentLinkCheckout;