import React from "react";
import { Box } from "@mui/material";
import InvoicePageProductsModal from "../InvoicePageProductsModal";

const ProductList = ({
  fontStyle,
  productsData,
  paymentInfo,
  visibleInPaymentPage,
  from,
  getDataFromReducer,
}) => {
  return (
    <Box className="product-section">
      <InvoicePageProductsModal
        from={from}
        paymentInfo={paymentInfo}
        fontFamily={fontStyle.fontFamily}
        color={fontStyle.color}
        products={productsData?.products}
        isShowViewMoreDetails={productsData?.isShowViewMoreDetails}
        visibleInPaymentPage={visibleInPaymentPage}
        isShowItemsHeader={true}
        getDataFromReducer={getDataFromReducer}
      />
    </Box>
  );
};

export default ProductList;
