import React, { useEffect } from "react";
import ReactGA4 from "react-ga4";
import history from "./history";

const GoogleAnalytics = () => {
  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      ReactGA4.initialize("G-C3J9YL4PST");
      ReactGA4.send({ hitType: "pageview", page: history.location.pathname });
    }
  }, []);

  return <></>;
};

export default GoogleAnalytics;
