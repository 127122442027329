import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";

function PayViaOptions({
  from,
  className,
  classNameForLogo = "",
  paymentPageSettings,
  dynamicWalletData,
}) {
  const [walletOptions, setWalletOptions] = useState(null);

  useEffect(() => {
    if (dynamicWalletData?.length > 0) {
      setWalletOptions(dynamicWalletData);
    } else if (paymentPageSettings) {
      setWalletOptions(paymentPageSettings?.walletsLogo);
    } else {
      setWalletOptions(null);
    }
  }, [paymentPageSettings, dynamicWalletData]);

  return (
    <>
      <Box
        className={className}
        sx={{
          justifyContent: from === "oneQR" ? "unset" : "center",
          marginTop: from === "oneQR" ? "8px !important" : 0,
        }}
      >
        {walletOptions?.map((option) => {
          return (
            <img
              src={option.logoUrl}
              alt="logo"
              height={from === "oneQR" ? 16 : 15}
              width={from === "oneQR" ? 70 : 65}
              key={option.id}
              className={`${classNameForLogo} logo-class`}
            />
          );
        })}
      </Box>
    </>
  );
}

export default PayViaOptions;
