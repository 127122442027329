import Backdrop from '@mui/material/Backdrop';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { speedPreloader } from './images';
import classNames from 'classnames';

const PageLoader = ({ open, alt, customClass, sx }) => {
    const classes = classNames('backdrop-loader', customClass);
    return (
        <Backdrop
            className={classes}
            open={open}
            sx={sx || {}}
        >
            <Card>
                <CardMedia
                    component="img"
                    image={speedPreloader}
                    alt={alt}
                />
            </Card>
        </Backdrop>
    );
};

export default PageLoader;