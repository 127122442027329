import Main from "./container/Main";
import { Router } from "react-router-dom";
import history from "@speed/common/src/components/history";
import "./assets/styles/main.scss";
import { StyledEngineProvider } from "@mui/material/styles";

function App() {
  return (
    <Router history={history}>
      <StyledEngineProvider injectFirst>
        <Main />
      </StyledEngineProvider>
    </Router>
  );
}

export default App;
