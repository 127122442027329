import { Box } from "@mui/material";
import React, { useState } from "react";
import { QRCodeComponent } from "@speed/common/src/components/QRCode/QRCode";
import Text from "@speed/common/src/components/Text/Text";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import CheckCircleSharpIcon from "@mui/icons-material/CheckCircleSharp";
import { Modal } from "./Modal/Modal";
import Clipboard from "./Clipboard/Clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { copyAddressLabel, openWalletLabel } from "../messages";

const PaymentLinkQRCode = ({
  fontStyle,
  paymentLinkMethodTitle,
  isMobile,
  openToWalletUrl,
  visibleInPaymentPage,
  qrActionBtnWidth,
  paymentAddress,
  paymentRequestWithAmount,
}) => {
  const [copied, setCopied] = useState(false);
  const [copiedBox, setCopiedBox] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const QRSizeLevel =
    (paymentLinkMethodTitle === "btcOnChain" ||
      paymentLinkMethodTitle === "ethereum") &&
    paymentAddress
      ? "H"
      : "L";

  const paymentAddressToBeCopy =
    paymentLinkMethodTitle === "btcOnChain"
      ? paymentRequestWithAmount
      : paymentAddress;

  const copyToClipboard = (setCopy) => {
    if (visibleInPaymentPage) {
      navigator?.clipboard
        ?.writeText(paymentAddress)
        .then(() => {
          setCopy(true);
          setTimeout(() => {
            setCopy(false);
          }, 1000);
        })
        .catch(() => {});
    }
  };

  const handleQRCode = () => {
    if (visibleInPaymentPage) {
      if (isMobile) copyToClipboard(setCopied);
      else setOpenModal(true);
    }
  };

  const openWalletBoxProps = {
    className: "qr-footer-box",
    sx: visibleInPaymentPage ? { cursor: "pointer" } : {},
  };

  if (visibleInPaymentPage) {
    openWalletBoxProps.component = "a";
    openWalletBoxProps.href = openToWalletUrl;
    openWalletBoxProps.target = "_blank";
  }

  return (
    <>
      <Modal
        fullWidth={false}
        className="qr-modal"
        maxWidth="lg"
        body={
          <QRCodeComponent
            value={paymentAddressToBeCopy}
            size={420}
            icon
            iconSize={25}
            level={QRSizeLevel}
          />
        }
        handleClose={() => setOpenModal(false)}
        open={openModal}
        closeIcon={false}
        disableEscapeKeyDown={false}
      />
      <Box>
        <QRCodeComponent
          value={paymentAddressToBeCopy}
          size="96%"
          icon
          iconSize={25}
          level={QRSizeLevel}
          onClick={handleQRCode}
          style={visibleInPaymentPage ? { cursor: "pointer" } : {}}
        />
      </Box>
      <CustomTooltip
        arrow={false}
        open={copied}
        placement={"top"}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        text={
          <>
            <CheckCircleSharpIcon />
            <span className="copied-clipboard-text">Copied</span>{" "}
          </>
        }
      >
        <Box className="crypto-address-text">
          <Text
            onClick={() => copyToClipboard(setCopied)}
            size={16}
            sx={{
              ...fontStyle,
              wordBreak: "break-word",
              marginRight: "5px",
              cursor: visibleInPaymentPage ? "pointer" : "initial",
            }}
            font="regular"
            className="grey-text text-truncate"
            variant="subtitle1"
            align="center"
          >
            {paymentAddress}
          </Text>
          {visibleInPaymentPage && (
            <Clipboard text={paymentAddress} tabIndex="-1" />
          )}
        </Box>
      </CustomTooltip>
      <Box className="payment-page-qr-footer">
        <CustomTooltip
          arrow={false}
          open={copiedBox}
          placement="top"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          text={
            <>
              <CheckCircleSharpIcon />
              <span className="copied-clipboard-text">Copied</span>{" "}
            </>
          }
        >
          <Box
            className="qr-footer-box"
            width={qrActionBtnWidth}
            onClick={() => copyToClipboard(setCopiedBox)}
            sx={
              visibleInPaymentPage
                ? { cursor: "pointer", marginRight: "10px" }
                : { marginRight: "10px" }
            }
          >
            <Text
              font="regular"
              className="grey-text"
              variant="inline"
              withIcon="start"
              size={16}
              sx={{ ...fontStyle, whiteSpace: "nowrap" }}
            >
              <FileCopyIcon
                onClick={() => copyToClipboard(setCopiedBox)}
                sx={{ height: "18px", width: "18px" }}
              />
              {copyAddressLabel}
            </Text>
          </Box>
        </CustomTooltip>
        <Box {...openWalletBoxProps} width={qrActionBtnWidth}>
          <Text
            font="regular"
            className="grey-text"
            variant="inline"
            withIcon="start"
            size={16}
            sx={{ ...fontStyle, whiteSpace: "nowrap" }}
          >
            <AccountBalanceWalletOutlinedIcon
              sx={{ height: "18px", width: "18px" }}
            />
            {openWalletLabel}
          </Text>
        </Box>
      </Box>
    </>
  );
};
export default PaymentLinkQRCode;
