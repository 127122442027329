import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import Text from "./Text/Text";
import {
  amountPaidLabel,
  amountRemaining,
  invoiceContactUsTxt,
  salesTax,
  shipping,
  subTotal,
  totalDue,
  totalExcludingTax,
} from "../messages";
import CustomDivider from "./Divider/Divider";
import {
  clipboardElement,
  dateTimeFormat,
  getAmountByCurrency,
  getCurrencyObj,
  totalAmountInvoiceUI,
} from "./constants";

const ProductBillingInfo = ({
  from,
  paymentInfo,
  fontFamily,
  getDataFromReducer,
  user,
}) => {
  const payment = paymentInfo?.payment;

  const renderGridData = (
    name,
    amount,
    font = "regular",
    className = "default"
  ) => {
    return (
      <>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          marginBottom="18px"
        >
          <Text
            size={14}
            font={font}
            variant="subtitle"
            className={`${className}-text`}
            fontFamily={fontFamily}
            fontWeight={600}
          >
            {name}
          </Text>
          <Text
            size={14}
            font={font}
            variant="subtitle"
            className={`${className}-text`}
            fontFamily={fontFamily}
            fontWeight={600}
          >
            {amount}
          </Text>
        </Grid>
      </>
    );
  };

  const renderPaidPayments = (payment) => {
    return (
      <Box marginBottom="10px" width="100%">
        <Box display="flex" justifyContent="space-between">
          {clipboardElement(payment?.id, true, "paid-payment-id", "text", true)}
          <Text
            size={14}
            font="semibold"
            variant="subtitle"
            className="default-text"
            fontFamily={fontFamily}
            fontWeight={600}
          >
            <>
              {getCurrencyObj(payment?.target_currency)?.symbol}
              {getAmountByCurrency(
                payment?.amount_paid,
                payment?.target_currency
              )}
            </>
          </Text>
        </Box>
        <Text
          size="14"
          className="grey-text"
          variant="subtitle1"
          font="regular"
        >
          {dateTimeFormat(
            payment?.created,
            "MM/DD/YYYY",
            paymentInfo?.payment?.timezone || null
          )}
        </Text>
      </Box>
    );
  };

  return (
    <Box className="invoice-info-detail" marginTop="30px">
      <Grid container justifyContent="space-between">
        {/* subtotal */}
        {/* {renderGridData(
          subTotal,
          payment?.amount,
          payment?.currency,
          "semibold"
        )} */}
        {/* <CustomDivider
          sx={{
            width: "100%",
            borderColor: "#e4e9ee",
            marginBottom: "10px",
          }}
        />
        {renderGridData(shipping, "$10.00", null, "grey")}
        {renderGridData(totalExcludingTax, "$2350.00", "semibold")}
        {renderGridData(salesTax, "$10.00", null, "grey")} */}
        {from !== "email-preview" && (
          <CustomDivider
            sx={{
              width: "100%",
              borderColor: "#e4e9ee",
              marginBottom: "10px",
            }}
          />
        )}
        {renderGridData(
          totalDue,
          getDataFromReducer ? (
            totalAmountInvoiceUI(payment?.invoice_line_items, payment?.currency)
          ) : (
            <>
              {getCurrencyObj(payment?.currency)?.symbol}
              {getAmountByCurrency(payment?.amount_pending, payment?.currency)}
            </>
          ),
          "semibold"
        )}
        <CustomDivider
          sx={{
            width: "100%",
            borderColor: "#e4e9ee",
            marginBottom: "10px",
          }}
        />
        {renderGridData(
          amountPaidLabel,
          getDataFromReducer ? (
            0
          ) : (
            <>
              {getCurrencyObj(payment?.currency)?.symbol}
              {getAmountByCurrency(payment?.amount_paid, payment?.currency)}
            </>
          ),
          "semibold"
        )}
        {/* to display payments in invoice details drawer */}
        {/* {(WebPreviewProps?.isFromInvoice ||
          mobilePreviewProps?.isFromInvoice) &&
          payment?.payments?.map((payment) => {
            return renderPaidPayments(payment);
          })} */}
        {renderGridData(
          amountRemaining,
          getDataFromReducer ? (
            totalAmountInvoiceUI(payment?.invoice_line_items, payment?.currency)
          ) : (
            <>
              {getCurrencyObj(payment?.currency)?.symbol}
              {getAmountByCurrency(payment?.amount_pending, payment?.currency)}
            </>
          ),
          "semibold"
        )}
        {from === "email-preview" && (
          <>
            <CustomDivider
              sx={{
                width: "100%",
                borderColor: "#e4e9ee",
                marginBottom: "10px",
              }}
            />
            <Text
              size={14}
              font="regular"
              variant="subtitle"
              className="grey-text"
              fontFamily={fontFamily}
            >
              {invoiceContactUsTxt[0]} <b>{user?.email}</b>{" "}
              {user?.phone_number && (
                <>
                  {invoiceContactUsTxt[1]} <b>{user?.phone_number}</b>
                </>
              )}
            </Text>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ProductBillingInfo;
